import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const OrderDetail = lazy(() => import('./order-detail'));
const OrderManagementPage = lazy(() => import('./order-list'));

const route = [
  {
    identifier: FUNCTION_WEB.ORDER_MANAGEMENT,
    path: '/order-management',
    element: OrderManagementPage
  },
  {
    identifier: FUNCTION_WEB.ORDER_MANAGEMENT_DETAIL,
    path: '/order-management/:code',
    element: OrderDetail
  }
];

export default route;

import { BaseGetListRequest } from '../types';

export interface GetListUserBody extends BaseGetListRequest {
  status?: string[];
  startDate?: string;
  endDate?: string;
  profileStatus?: string[];
  _id?: string;
}

export enum GENDER {
  Male,
  Female,
  Other
}

export type ActiveUserParams = {
  _id: string;
};

export enum PROFILE_STATUS {
  VERIFYING = 'VERIFYING',
  VERIFYED = 'VERIFYED'
}

import { Images } from 'assets/Images';

export default function LockSuccessIcon() {
  return (
    <img
      style={{
        width: 56
      }}
      src={Images.icLockSuccess}
      alt="icon lock"
    />
  );
}

import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const DepartmentManagementPage = lazy(() => import('./department-list'));

const route = [
  {
    identifier: FUNCTION_WEB.DEPARTMENT_MANAGEMENT,
    path: '/department-management',
    element: DepartmentManagementPage
  }
];

export default route;

import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const UserListPage = lazy(() => import('./user-list'));
const UserDetail = lazy(() => import('./user-detail'));

const route = [
  {
    identifier: FUNCTION_WEB.USER_MANAGEMENT,
    path: '/user-management',
    element: UserListPage
  },
  {
    identifier: FUNCTION_WEB.USER_DETAIL,
    path: '/user-management/:user_id',
    element: UserDetail
  }
];

export default route;

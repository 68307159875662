import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const DashBoard = lazy(() => import('.'));

const route = [
  {
    identifier: FUNCTION_WEB.DASHBOARD,
    path: '/',
    element: DashBoard
  }
];

export default route;

import { Images } from 'assets/Images';

export default function CloseIcon() {
  return (
    <img
      style={{
        width: 16,
        height: 16
      }}
      src={Images.icClose}
      alt="Close"
    />
  );
}

import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const DiscountManagement = lazy(() => import('./index'));
const AddDiscount = lazy(() => import('./add-discount'));
const DiscountDetail = lazy(() => import('./discount-detail'));

const route = [
  {
    identifier: FUNCTION_WEB.DISCOUNT_MANAGEMENT,
    path: '/discount-management',
    element: DiscountManagement
  },
  {
    identifier: FUNCTION_WEB.ADD_DISCOUNT,
    path: '/discount-management/add-discount',
    element: AddDiscount
  },
  {
    identifier: FUNCTION_WEB.DISCOUNT_DETAIL,
    path: '/discount-management/:_id',
    element: DiscountDetail
  }
];

export default route;

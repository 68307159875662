import Staff from 'pages/staff-management/routes';
import Login from 'pages/login/route';
import UserManagement from 'pages/user-management/routes';
import ManagerBanner from 'pages/manager-banner/route';
import DiscountManagement from 'pages/discount-management/route';
import OrderManagement from 'pages/order-management/routes';
import BoxManagement from 'pages/box-management/routes';
import BoxSlotManagement from 'pages/box-slot-management/routes';
import PriceManagement from 'pages/price-management/routes';
import DashBoard from 'pages/dashboard/route';
import AdvertisingManagement from 'pages/advertising-management/routes';
import AritcleManagement from 'pages/article-management/routes';
import DriverManagement from 'pages/driver-management/routes';
import RetrieveManagement from 'pages/retriveve-management/routes';
import PromotionManagement from 'pages/promotion-management/routes';
import DepartmentManagement from 'pages/department-management/routes';
import PermissionManagement from 'pages/permission-management/routes';
import NotificationManagement from 'pages/notification-management/routes';
import MiniGameManagement from 'pages/mini-game-management/routes';
import DriverAccountManagement from 'pages/driver-account-management/routes';

const routes = [
  ...DashBoard,
  ...ManagerBanner,
  ...Staff,
  ...Login,
  ...UserManagement,
  ...DiscountManagement,
  ...OrderManagement,
  ...BoxManagement,
  ...BoxSlotManagement,
  ...AdvertisingManagement,
  ...PriceManagement,
  ...AritcleManagement,
  ...DriverManagement,
  ...RetrieveManagement,
  ...PromotionManagement,
  ...DepartmentManagement,
  ...PermissionManagement,
  ...NotificationManagement,
  ...MiniGameManagement,
  ...DriverAccountManagement
];

export default routes;

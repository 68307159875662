import { Tooltip } from 'antd';
import { Images } from 'assets/Images';
import { IconSize, IconProps, IconStyles } from 'types/icon';

export default function UnLockBagIcon({ size = IconSize.DEFAULT, className, onClick }: IconProps) {
  return (
    <Tooltip title="Mở">
      <img
        src={Images.icUnLock}
        alt="UnLock Bag"
        style={{
          width: size,
          height: size,
          ...IconStyles.default
        }}
        onClick={onClick}
        className={className}
      />
    </Tooltip>
  );
}

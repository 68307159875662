import React from 'react';

export default function LockerIcon({ imageStyles }: any) {
  return (
    <span className="anticon anticon-appstore ant-menu-item-icon">
      <svg width={24} height={19} viewBox="0 0 24 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="ant-menu-item-icon" style={imageStyles}>
        <rect x="0.75" y="0.75" width="22.5" height="17.5" rx="2.25" stroke="#A6ADB4" strokeWidth="1.5" />
        <rect x={3} y={3} width={18} height={13} rx={2} fill="currentColor" />
        <circle cx="5.5" cy="9.5" r="1.5" fill="#001529" />
      </svg>
    </span>
  );
}

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import rootReducer from './reducers';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import rootSaga from 'saga';

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const sagaMiddleware = createSagaMiddleware();

const store = configureStore({ reducer: persistedReducer, middleware: [sagaMiddleware] });

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;

import { Images } from 'assets/Images';
export default function LogAvatarIcon() {
  return (
    <img
      style={{
        width: 24,
        height: 24
      }}
      src={Images.icLogAvatar}
      alt="LogAvatar"
    />
  );
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './langs/en.json';
import vn from './langs/vn.json';

export enum LanguageCode {
  VN = 'vn',
  EN = 'en'
}

i18n.use(initReactI18next).init({
  lng: LanguageCode.VN,
  fallbackLng: LanguageCode.VN,
  debug: false,
  resources: {
    en: {
      translation: en
    },
    vn: {
      translation: vn
    }
  }
});

export default i18n;

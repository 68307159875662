import { useDispatch } from 'react-redux';
import { api } from 'service';
import UserAction from 'reduxes/reducers/user-reducer';
import * as storage from 'utils/storage';
import { useNavigate } from 'react-router-dom';

function useAuthen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSignOut = () => {
    api.signOut().then(() => {
      dispatch(UserAction.logout());
      storage.clear();
      navigate('/login');
    });
  };

  return {
    onSignOut
  };
}

export default useAuthen;

const config = {
  siteName: 'OneBox',
  copyright: 'Power by Infinity Valley ©2021',
  logoPath: '/logo.png',
  apiPrefix: '/api/v1',
  fixedHeader: true, // sticky primary layout header

  /* Layout configuration, specify which layout to use for route. */
  layouts: [
    {
      name: 'primary',
      include: [/.*/],
      exclude: [/(\/(en|zh))*\/login/]
    }
  ],

  /* I18n configuration, `languages` and `defaultLanguage` are required currently. */
  i18n: {
    /* Countrys flags: https://www.flaticon.com/packs/countrys-flags */
    languages: [
      {
        key: 'vi',
        title: 'Việt Nam',
        flag: '/flags/vietnam.png'
      },
      {
        key: 'en',
        title: 'English',
        flag: '/flags/america.png'
      }
    ],
    defaultLanguage: 'vi'
  },

  /* We will save these store to storage */
  store: {
    whitelist: ['userStore', 'menuStore']
  }
};

export default config;

import { Tooltip } from 'antd';
import { Images } from 'assets/Images';
import { IconSize, IconProps, IconStyles } from 'types/icon';

export default function LockIcon({ size = IconSize.DEFAULT, className, onClick }: IconProps) {
  return (
    <Tooltip title="Khoá">
      <img
        src={Images.icLock}
        alt="icLock"
        style={{
          width: size,
          height: size,
          ...IconStyles.default
        }}
        onClick={onClick}
        className={className}
      />
    </Tooltip>
  );
}

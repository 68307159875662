import { Header, Sider, Bread } from 'components/layouts';
import { Layout, Drawer } from 'antd';
import './style.scss';
import { observer } from 'mobx-react-lite';
import { useMobileLayout } from 'hooks/layout';
import config from 'utils/config';
import { MenuType } from 'api/service-type';
import Page404 from 'pages/error/404';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reduxes/reducers';
import AppAction from '../../reduxes/reducers/app-reducer';
import { useMemo } from 'react';
import { useFilterMenu } from 'router/permission-route';

const { Content } = Layout;

function PrimaryLayout({ children }: any) {
  const isMobile = useMobileLayout();
  const appState = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();

  const menus: Array<MenuType> = useFilterMenu();

  const hasPermission = true;
  const onCollapseChange = (collapsed: any) => {
    dispatch(AppAction.setCollapsed(collapsed));
  };

  const headerProps = {
    collapsed: appState.collapsed,
    onCollapseChange,
    fixed: config.fixedHeader
  };

  const siderProps = {
    menus,
    isMobile,
    collapsed: appState.collapsed,
    onCollapseChange
  };

  const NewFooter = useMemo(() => CustomFooter(appState.footer, appState.footerProps), [appState]);

  return (
    <>
      <Layout className="primary-layout">
        {isMobile ? (
          <Drawer
            maskClosable
            closable={false}
            onClose={() => onCollapseChange(!appState.collapsed)}
            visible={!appState.collapsed}
            placement="left"
            width={200}
            style={{
              height: '100vh'
            }}
            className="pa-0"
          >
            <Sider {...siderProps} collapsed={false} />
          </Drawer>
        ) : (
          <Sider {...siderProps} />
        )}
        <div id="primary-container" className="container" style={{ paddingTop: config.fixedHeader ? 72 : 0 }}>
          <Header {...headerProps} />
          <Content className="content">
            <Bread routeList={menus} />
            {hasPermission ? children : <Page404 />}
            {appState.hasFooter && appState.footer ? <NewFooter /> : null}
          </Content>
        </div>
      </Layout>
    </>
  );
}

export default observer(PrimaryLayout);

const CustomFooter = (Component: any, props: any) => {
  return () => <Component {...props} />;
};

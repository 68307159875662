import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const PermissionPage = lazy(() => import('./permission-list'));
const AddPermission = lazy(() => import('./permission-add'));
const PermissionDetail = lazy(() => import('./permission-detail'));

const route = [
  {
    identifier: FUNCTION_WEB.PERMISSION_MANAGEMENT,
    path: '/permission-management',
    element: PermissionPage
  },
  {
    identifier: FUNCTION_WEB.PERMISSION_MANAGEMENT_ADD,
    path: '/permission-management/permission-add',
    element: AddPermission
  },
  {
    identifier: FUNCTION_WEB.PERMISSION_MANAGEMENT_DETAIL,
    path: '/permission-management/:_id',
    element: PermissionDetail
  },
];

export default route;

import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { USER_ROLE, CSKH_ROLES, FUNCTION_WEB, ROUTER_PATH, MKT_ROLES, TTNB_ROLES, VH_ROLES, TH_ROLES } from 'types/permission';
import { MenuType } from 'api/service-type';
import { useSelector } from 'react-redux';
import { RootState } from 'reduxes/reducers';
import * as faker from 'api/faker';

interface Props {
  children: ReactElement;
  role: USER_ROLE | string;
  identifier: FUNCTION_WEB;
  path: string;
}

export function useFilterMenu() {
  // const userName = useSelector((state: RootState) => state.user.user.username);
  // const menus: Array<MenuType> = faker.getMenu();
  // //console.log("menus1", menus1);
  // switch (userName) {
  //   case USER_ROLE.CSKH: {
  //     return menus.filter((item) => {
  //       return CSKH_ROLES.includes(item.route as any);
  //     });
  //   }
  //   case USER_ROLE.MKT: {
  //     return menus.filter((item) => {
  //       return MKT_ROLES.includes(item.route as any);
  //     });
  //   }
  //   case USER_ROLE.TTNB: {
  //     return menus.filter((item) => {
  //       return TTNB_ROLES.includes(item.route as any);
  //     });
  //   }
  //   case USER_ROLE.VH: {
  //     return menus.filter((item) => {
  //       return VH_ROLES.includes(item.route as any);
  //     });
  //   }
  //   case USER_ROLE.TH: {
  //     return menus.filter((item) => {
  //       return TH_ROLES.includes(item.route as any);
  //     });
  //   }

  // }

  //  return menus;
  const userPermission = useSelector((state: RootState) => state.user.userPermission);
  const menus: Array<MenuType> = [];
  userPermission?.map((item: any) => {
    if(item.isMenu){
      const itemMenu: MenuType = {
        route: item.route != undefined ? item.route : '/',
        name: item.name,
        id: item.id,
        menu_parent_id: item.menu_parent_id,
        icon: item.icon
      };
    
      menus.push(itemMenu);
    }
    });
    
  return menus;
}

function PermissionRoute({ children, role, path }: Props) {
  const firstPath = `/${path.split('/')?.[1]}`;
  const userPermission = useSelector((state: RootState) => state.user.userPermission);
  const findItem = userPermission.find((x: any) => x.route === firstPath);
  if (!findItem && firstPath !== "/login") {
    return <Navigate to="/not-found" replace />;
  }
  //console.log('firstPath', firstPath);
  // switch (role) {
  //   case USER_ROLE.CSKH: {
  //     if (!CSKH_ROLES.includes(firstPath as ROUTER_PATH)) {
  //       return <Navigate to="/not-found" replace />;
  //     }

  //     break;
  //   }
  //   case USER_ROLE.MKT: {
  //     if (!MKT_ROLES.includes(firstPath as ROUTER_PATH)) {
  //       return <Navigate to="/not-found" replace />;
  //     }

  //     break;
  //   }
  //   case USER_ROLE.VH: {
  //     if (!VH_ROLES.includes(firstPath as ROUTER_PATH)) {
  //       return <Navigate to="/not-found" replace />;
  //     }

  //     break;
  //   }
  //   case USER_ROLE.TH: {
  //     if (!TH_ROLES.includes(firstPath as ROUTER_PATH)) {
  //       return <Navigate to="/not-found" replace />;
  //     }

  //     break;
  //   }
  // }

  return children;
}

export default PermissionRoute;

import { ORDER_STATUS } from 'pages/order-management/order-list/types';
import { ActionBody } from 'reduxes/types';
import { createActions, createReducer, DefaultActionTypes } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import {
  State,
  AppActionCreators,
  Action,
  SetCollapsed,
  SetOpenKeys,
  SetUserRoles,
  SetPartners,
  SetHasFooter,
  SetFooter,
  SetFooterProps,
  SetCities,
  SetDistricts,
  SetWards
} from './types';

export type AppState = State;

export const INITIAL_STATE = Immutable<State>({
  collapsed: false,
  openKeys: [],
  loading: false,
  userRoles: [],
  partners: [],
  cities: [],
  districts: [],
  wards: [],
  hasFooter: false,
  footer: null,
  footerProps: {
    current: 1,
    pageSize: 10,
    total: 0,
    onChange: () => null,
    data: [],
    fileName: '',
    price: 0,
    blocks: 0,
    setIsUpdateStatusOpen: null
  }
});

export interface AppActionTypes extends DefaultActionTypes {
  SET_COLLAPSED: 'SET_COLLAPSED';
  SET_OPEN_KEYS: 'SET_OPEN_KEYS';
  SET_USER_ROLES: 'SET_USER_ROLES';
  SET_PARTNERS: 'SET_PARTNERS';
  SET_HAS_FOOTER: 'SET_HAS_FOOTER';
  SET_FOOTER: 'SET_FOOTER';
  SET_FOOTER_PROPS: 'SET_FOOTER_PROPS';
  SET_CITIES: 'SET_CITIES';
  SET_DISTRICTS: 'SET_DISTRICTS';
  SET_WARDS: 'SET_WARDS';
}

const { Types, Creators } = createActions<AppActionTypes, AppActionCreators>({
  setLoading: [''],
  setCollapsed: ['collapsed'],
  setOpenKeys: ['openKeys'],
  setUserRoles: ['userRoles'],
  setPartners: ['partners'],
  setHasFooter: ['hasFooter'],
  setFooter: ['footer'],
  setFooterProps: ['footerProps'],
  setCities: ['cities'],
  setDistricts: ['districts'],
  setWards: ['wards']
});

export const AppTypes = Types;

export default Creators;

const setCollapsed: ActionBody<SetCollapsed> = (state, { collapsed }) => ({
  ...state,
  collapsed
});

const setOpenKeys: ActionBody<SetOpenKeys> = (state, { openKeys }) => ({
  ...state,
  openKeys
});

const setUserRoles: ActionBody<SetUserRoles> = (state, { userRoles }) => {
  return {
    ...state,
    userRoles
  };
};

const setPartners: ActionBody<SetPartners> = (state, { partners }) => {
  return {
    ...state,
    partners
  };
};

const setHasFooter: ActionBody<SetHasFooter> = (state, { hasFooter }) => {
  return {
    ...state,
    hasFooter
  };
};

const setFooter: ActionBody<SetFooter> = (state, { footer }) => {
  return {
    ...state,
    footer
  };
};

const setFooterProps: ActionBody<SetFooterProps> = (state, { footerProps }) => {
  return {
    ...state,
    footerProps
  };
};

const setCities: ActionBody<SetCities> = (state, { cities }) => {
  return {
    ...state,
    cities
  };
};

const setDistricts: ActionBody<SetDistricts> = (state, { districts }) => {
  return {
    ...state,
    districts
  };
};

const setWards: ActionBody<SetWards> = (state, { wards }) => {
  return {
    ...state,
    wards
  };
};

export const reducer = createReducer<any, Action>(INITIAL_STATE, {
  [Types.SET_COLLAPSED]: setCollapsed,
  [Types.SET_OPEN_KEYS]: setOpenKeys,
  [Types.SET_USER_ROLES]: setUserRoles,
  [Types.SET_PARTNERS]: setPartners,
  [Types.SET_HAS_FOOTER]: setHasFooter,
  [Types.SET_FOOTER]: setFooter,
  [Types.SET_FOOTER_PROPS]: setFooterProps,
  [Types.SET_CITIES]: setCities,
  [Types.SET_DISTRICTS]: setDistricts,
  [Types.SET_WARDS]: setWards
});

export enum UserStatus {
  ACTIVE = 'ACTIVE', //Đang hoạt động
  LOCK = 'LOCK', // Đã khóa
  INACTIVE = 'INACTIVE', // Ngừng hoạt động
  VERIFYING = 'VERIFYING', // Chưa xác thực
  VERIFYED = 'VERIFYED' // Đã xác thực
}

export interface UserType {
  code: string;
  address: string;
  autoOrderConfirm: boolean;
  avatar: string;
  createdAt: string;
  dob: string;
  email: string;
  fullName: string;
  gender: 'Male' | 'Female';
  note: string;
  partnerName: string;
  personalId: string;
  phoneNumber: string;
  status: UserStatus;
  _id: string;
}

import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const PriceBoxOrder = lazy(() => import('./price-list'));

const route = [
  {
    identifier: FUNCTION_WEB.PRICE_MANAGEMENT,
    path: '/price-management',
    element: PriceBoxOrder
  }
];

export default route;

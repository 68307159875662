import { Breadcrumb } from 'antd';
import { MenuType } from 'api/service-type';
import { useTranslation } from 'react-i18next';
import { pathToRegexp } from 'path-to-regexp';
import { Link, useLocation } from 'react-router-dom';
import { queryAncestors } from 'utils';
import iconMap from 'utils/icon-map';
import './style.scss';

interface BreadcrumbItem {
  id: number;
  name: string;
  icon: string;
  menu_parent_id?: number;
  route?: string;
}

interface BreadPropsType {
  routeList: Array<MenuType>;
}

function Bread({ routeList }: BreadPropsType) {
  const location = useLocation();
  const { t } = useTranslation();

  // Find a route that matches the pathname.
  const currentRoute = routeList.find((item) => item.route && pathToRegexp(item.route).exec(location.pathname));

  // Find the breadcrumb navigation of the current route match and all its ancestors.
  const paths = currentRoute
    ? queryAncestors(routeList, currentRoute, 'menu_parent_id').reverse()
    : [
        routeList[0],
        {
          id: 404,
          name: 'Not Found'
        }
      ];

  const generateBreadcrumbs = (path: Array<BreadcrumbItem> | BreadcrumbItem) => {
    if (!Array.isArray(path)) {
      return (
        <Breadcrumb.Item>
          <>{path.icon && <span style={{ marginRight: 4 }}>{iconMap[path.icon]}</span>}</>
        </Breadcrumb.Item>
      );
    }

    return path.map((item, key) => {
      const content = item && (
        <>
          {item.icon && <span style={{ marginRight: 4 }}>{iconMap[item.icon]}</span>}
          {t(item.name)}
        </>
      );

      return item && <Breadcrumb.Item key={key}>{path.length - 1 !== key ? <Link to={item.route || '#'}>{content}</Link> : content}</Breadcrumb.Item>;
    });
  };

  return currentRoute ? <Breadcrumb className="bread">{generateBreadcrumbs(paths.pop())}</Breadcrumb> : null;
}

export default Bread;

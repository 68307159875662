import { Images } from 'assets/Images';

export default function CheckOutlinedIcon() {
  return (
    <img
      style={{
        width: 24,
        height: 24
      }}
      src={Images.icDriverInactive}
      alt="icDriverInactive"
    />
  );
}

import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const RetrieveDetail = lazy(() => import('./retrieve-detail'));
const RetrieveManagementPage = lazy(() => import('./retrieve-list'));

const route = [
  {
    identifier: FUNCTION_WEB.RETRIEVE_MANAGENENT,
    path: '/retrieve-management',
    element: RetrieveManagementPage
  },
  {
    identifier: FUNCTION_WEB.RETRIEVE_MANAGENENT_DETAIL,
    path: '/retrieve-management/:code',
    element: RetrieveDetail
  }
];

export default route;

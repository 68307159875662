import { Images } from 'assets/Images';
export default function BackDetaiUser() {
  return (
    <img
      style={{
        width: 30,
        height: 30,
        margin: 5
      }}
      src={Images.icBackDetailUser}
      alt="BackDetaiUser"
    />
  );
}

import { cloneDeep } from 'lodash';
import { pathToRegexp } from 'path-to-regexp';
import config from './config';
import { createIntl, createIntlCache } from 'react-intl';
import { notification } from 'antd';
import { NotificationConfig } from './types';
import NotificationCloseButton from 'components/notification/notification-close-button';
import { PROFILE_STATUS } from 'pages/user-management/user-list/types';
import { UserStatus } from 'types/user';
import { BOX_STATUS } from 'pages/box-management/types';
import { BOX_LOCK, BOX_SLOT_STATUS } from 'pages/box-slot-management/types';
import { TARGET_USER } from 'types/order';

export const languages = config.i18n ? config.i18n.languages.map((item) => item.key) : [];
export const defaultLanguage = config.i18n ? config.i18n.defaultLanguage : 'en';

export function queryArray(array: Array<any>, key: string, value: any): any {
  if (!Array.isArray(array)) {
    return;
  }
  return array.find((item) => item[key] === value);
}

export function arrayToTree(array: Array<any>, id = 'id', parentId = 'pid', children = 'children'): Array<any> {
  const result: Array<any> = [];
  const hash: Record<any, any> = {};
  const data = cloneDeep(array);

  data.forEach((item, index) => {
    hash[data[index][id]] = data[index];
  });

  data.forEach((item) => {
    const hashParent = hash[item[parentId]];
    if (hashParent) {
      !hashParent[children] && (hashParent[children] = []);
      hashParent[children].push(item);
    } else {
      result.push(item);
    }
  });
  return result;
}

/**
 * In an array object, traverse all parent IDs based on the value of an object.
 */
export function queryPathKeys(array: Array<any>, current: any, parentId: string, id = 'id'): Array<any> {
  const result = [current];
  const hashMap = new Map();
  array.forEach((item) => hashMap.set(item[id], item));

  const getPath = (currentPath: any) => {
    const currentParentId = hashMap.get(currentPath)[parentId];
    if (currentParentId) {
      result.push(currentParentId);
      getPath(currentParentId);
    }
  };

  getPath(current);
  return result;
}

/**
 * In an array of objects, specify an object that traverses the objects whose parent ID matches.
 */
export function queryAncestors(array: Array<any>, current: any, parentId: string, id = 'id'): Array<any> {
  const result = [current];
  const hashMap = new Map();
  array.forEach((item) => hashMap.set(item[id], item));

  const getPath = (currentPath: any) => {
    const currentParentId = hashMap.get(currentPath[id])[parentId];
    if (currentParentId) {
      result.push(hashMap.get(currentParentId));
      getPath(hashMap.get(currentParentId));
    }
  };

  getPath(current);
  return result;
}

export function isMatch(pathname: string, regepx: any) {
  return regepx instanceof RegExp ? regepx.test(pathname) : pathToRegexp(regepx).exec(pathname);
}

/**
 * Query which layout should be used for the current path based on the configuration.
 * @param   {layouts}     layouts   Layout configuration.
 * @param   {pathname}    pathname  Path name to be queried.
 * @return  {string}   Return frist object when query success.
 */
export function queryLayout(layouts: any, pathname: string): string {
  let result = 'public';

  for (const item of layouts) {
    let include = false;
    let exclude = false;
    if (item.include) {
      for (const regepx of item.include) {
        if (isMatch(pathname, regepx)) {
          include = true;
          break;
        }
      }
    }

    if (include && item.exclude) {
      for (const regepx of item.exclude) {
        if (isMatch(pathname, regepx)) {
          exclude = true;
          break;
        }
      }
    }

    if (include && !exclude) {
      result = item.name;
      break;
    }
  }

  return result;
}

export function getWorkspace(): string {
  const url = window.location.host;
  const parts = url.split('.');
  return parts[0] || '';
}

export function downloadURI(uri: string, name: string) {
  let link: HTMLAnchorElement | null = document.createElement('a');

  link.download = name;
  link.href = uri;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  link = null;
}

let intl: any;
let cache = createIntlCache();

export function refreshLanguage(language: string) {
  intl = createIntl(
    {
      locale: language
    },
    cache
  );
}

export function trans(id: string, values?: Record<string, any>) {
  if (!intl) {
    return id;
  }
  return intl.formatMessage({ id }, values);
}

export function notify(
  { status, duration = 3, message, description = '', placement = 'topRight', key = 'form-new-group' }: NotificationConfig,
  otherConfig: any = {}
) {
  let defaultMessage = status === 'error' ? 'Error' : 'Success';
  const defaultConfig = {
    duration,
    message: message || defaultMessage,
    description,
    placement,
    key,
    btn: NotificationCloseButton({ key }),
    ...otherConfig
  };

  if (status === 'error') {
    notification.error(defaultConfig);
  } else {
    notification.success(defaultConfig);
  }
}

export function formatMoney(_money: number = 0): string {
  const money = _money.toString().replace(/./g, function (c, i, a) {
    return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? '.' + c : c;
  });

  return money;
}

export const ExcelTranslation = {
  orderType: {
    [TARGET_USER.DRIVER]: 'Tài xế',
    [TARGET_USER.USER]: 'Khách hàng',
    [TARGET_USER.ALL]: 'Tất cả'
  },

  userStatus: {
    [UserStatus.ACTIVE]: 'Đang hoạt động',
    [UserStatus.INACTIVE]: 'Chờ xác thực',
    [UserStatus.LOCK]: 'Đã khoá',
    [UserStatus.VERIFYED]: 'Đã duyệt',
    [UserStatus.VERIFYING]: 'Chờ duyệt'
  },

  driverStatus: {
    [UserStatus.ACTIVE]: 'Đang hoạt động',
    [UserStatus.INACTIVE]: 'Chờ xác thực',
    [UserStatus.LOCK]: 'Đã khoá',
    [UserStatus.VERIFYED]: 'Đã duyệt',
    [UserStatus.VERIFYING]: 'Chờ duyệt'
  },

  profileStatus: {
    [PROFILE_STATUS.VERIFYED]: 'Đã duyệt',
    [PROFILE_STATUS.VERIFYING]: 'Chờ duyệt'
  },

  boxStatus: {
    [BOX_STATUS.AVAILABLE]: 'Đang hoạt động',
    [BOX_STATUS.UNAVAILABLE]: 'Ngừng hoạt động',
    [BOX_STATUS.LOCK]: 'Đã bị khoá'
  },

  boxSlotStatus: {
    [BOX_SLOT_STATUS.AVAILABLE]: 'Chưa sử dụng', //1
    [BOX_SLOT_STATUS.UNAVAILABLE]: 'Đang lưu trữ', //2
    [BOX_SLOT_STATUS.SAVEFORORDER]: 'Đang giữ tủ', //3
    [BOX_SLOT_STATUS.LOCK]: 'Đang khoá', //4
    [BOX_SLOT_STATUS.ISOPEN]: 'Đang mở' //10
  },

  boxLock: {
    [BOX_LOCK.LOCK]: 'Đang bị khoá'
  }
};

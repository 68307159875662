import { Tooltip } from 'antd';
import UserWinGame from 'assets/imgs/UserListWin.svg';
import { IconProps, IconSize, IconStyles } from 'types/icon';

function UserWinGameIcon({ size = IconSize.DEFAULT, className, onClick }: IconProps) {
  return (
    <Tooltip title="Danh sách người chiến thắng">
      <img
        src={UserWinGame}
        alt="Winner"
        style={{
          width: size,
          height: size,
          ...IconStyles.default
        }}
        className={className}
        onClick={onClick}
      />
    </Tooltip>
  );
}

export default UserWinGameIcon;

import { Tooltip } from 'antd';
import EyeShow from 'assets/imgs/EyeShow.svg';
import { IconProps, IconSize, IconStyles } from 'types/icon';

function EyeShowIcon({ size = IconSize.DEFAULT, className, onClick }: IconProps) {
  return (
    <Tooltip title="Hiện">
      <img
        src={EyeShow}
        alt="Edit"
        style={{
          width: size,
          height: size,
          ...IconStyles.default
        }}
        className={className}
        onClick={onClick}
      />
    </Tooltip>
  );
}

export default EyeShowIcon;

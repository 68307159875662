import { BaseGetListRequest } from 'pages/user-management/types';

export enum BOX_STATUS {
  AVAILABLE = 1,
  UNAVAILABLE = 2,
  LOCK = 3
}

export interface AdminGetBoxListBody extends BaseGetListRequest {
  code: string;
  name: string;
  status: BOX_STATUS | string;
  city?: string[];
  district?: string[];
  ward?: string[];
}

export type FormSearchProps = {
  handleSearch: (values: any) => void;
  initialValues: {
    code: string;
    name: string;
    status?: BOX_STATUS | string;
    city?: string;
    district?: string;
    ward?: string;
  };
  loading?: boolean;
};

export interface GetListWardsRequestDTO {
  cityId?: Array<string | undefined>;
  districtId?: Array<string | undefined>;
}

export interface UpdateBoxRequestDTO {
  name: string;
  address: string;
  city: string;
  district: string;
  ward: string;
  location: {
    coordinates: Array<number>;
  };
}

export interface AddBoxRequestDTO extends UpdateBoxRequestDTO {
  code: string;
  status: BOX_STATUS;
  color: number;
  slots: Array<any>;
}

export interface BoxsCustom {
  _keyNumber: number | string;
  lock: number;
  lockPort: number;
  position: number;
  size: string;
}

export type ChangePasswordBoxProps = {
  isOpen: boolean;
  onCancel: Function | any;
  currentBox: any;
};
export interface ChangePasswordRequestDTO {
  password: string;
  supperPassword?: string;
  boxId?: Array<string>;
}

export type PromotionBoxProps = {
  loading: boolean;
  dataSource: any;
  boxId?: string;
};

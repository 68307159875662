import { lazy } from 'react';

const Login = lazy(() => import('.'));

const route = [
  {
    path: '/login',
    element: Login
  }
];
export default route;

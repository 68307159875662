import {lazy} from 'react'
import { FUNCTION_WEB } from 'types/permission';

const PostList = lazy(()=>import('./post-list'))
const PostDetail = lazy(()=>import('./post-detail') )
const AddArticle = lazy(()=>import('./add-post'))

const routes = [
    {
        identifier: FUNCTION_WEB.ARTICLE_MANAGEMENT,
        path : '/article-management',
        element : PostList
    },
    {
        identifier: FUNCTION_WEB.ARTICLE_DETAIL,
        path : '/article-management/:id',
        element: PostDetail
    },
    {
        identifier: FUNCTION_WEB.ADD_ARTICLE_DETAIL,
        path : '/article-management/add-article',
        element: AddArticle
    }

]
export default routes;
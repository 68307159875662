import { createActions, createReducer, DefaultActionTypes } from 'reduxsauce';
import SeamlessImmutable from 'seamless-immutable';
import { Action, BoxManagementActionCreators, SetBoxTemplate, SetCurrentBox, SetCurrentBoxSlot, State } from './types';
import { ActionBody } from 'reduxes/types';

export type BoxManagementState = State;

export const INITIAL_STATE = SeamlessImmutable<State>({
  currentBox: {
    _id: ''
  },
  boxTemplate: [],
  currentBoxSlot: {
    _id: ''
  }
});

export interface BoxManagementActionTypes extends DefaultActionTypes {
  SET_CURRENT_BOX: 'SET_CURRENT_BOX';
  SET_BOX_TEMPLATE: 'SET_BOX_TEMPLATE';
  SET_CURRENT_BOX_SLOT: 'SET_CURRENT_BOX_SLOT';
}

const { Types, Creators } = createActions<BoxManagementActionTypes, BoxManagementActionCreators>({
  setCurrentBox: ['currentBox'],
  setBoxTemplate: ['boxTemplate'],
  setCurrentBoxSlot: ['currentBoxSlot']
});

export const BoxManagementType = Types;

export default Creators;

const setCurrentBox: ActionBody<SetCurrentBox> = (state, { currentBox }) => {
  return {
    ...state,
    currentBox
  };
};

const setBoxTemplate: ActionBody<SetBoxTemplate> = (state, { boxTemplate }) => {
  return {
    ...state,
    boxTemplate
  };
};

const setCurrentBoxSlot: ActionBody<SetCurrentBoxSlot> = (state, { currentBoxSlot }) => {
  return {
    ...state,
    currentBoxSlot
  };
};

export const reducer = createReducer<State, Action>(INITIAL_STATE, {
  [Types.SET_CURRENT_BOX]: setCurrentBox,
  [Types.SET_BOX_TEMPLATE]: setBoxTemplate,
  [Types.SET_CURRENT_BOX_SLOT]: setCurrentBoxSlot
});

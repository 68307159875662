export type IconProps = {
  size?: number;
  className?: string;
  onClick?: () => void;
  toolTip?: string;
};

export enum IconSize {
  DEFAULT = 28
}

export const IconStyles = {
  default: {
    cursor: 'pointer'
  }
};

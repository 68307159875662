import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';
const DriverList = lazy(() => import('./driver-list'));
const DriverDetail = lazy(() => import('./driver-detail'));
const routes = [
  {
    identifier: FUNCTION_WEB.DRIVER_MANAGEMENT,
    path: '/driver-management',
    element: DriverList
  },
  {
    identifier: FUNCTION_WEB.DRIVER_MANAGEMENT,
    path: '/driver-management/:id',
    element: DriverDetail
  }
];
export default routes;

import {
  ApiOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  CameraOutlined,
  CodeOutlined,
  DashboardOutlined,
  EditOutlined,
  LineOutlined,
  MailOutlined,
  MessageOutlined,
  PayCircleOutlined,
  ShoppingCartOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { Images } from 'assets/Images';
import { ReactElement } from 'react';
import UserIcon from 'assets/svg/components/UserIcon';
import OrderIcon from 'assets/svg/components/OrderIcon';
import VoucherIcon from 'assets/svg/components/VoucherIcon';
import Advertising from 'assets/svg/components/Advertising';
import CoinIcon from 'assets/svg/components/CoinIcon';
import MiniGameIcon from 'assets/svg/components/MiniGameIcon';

import NotificationIcon from 'assets/svg/components/Notification';
import { LockIcon } from 'components/icons';
import LockerIcon from 'assets/svg/components/LockerIcon';
import PostContent from 'assets/svg/components/PostContent';
import DriverIcon from 'assets/svg/components/DriverIcon';
import RetrieveIcon from 'assets/svg/components/RetrieveIcon';
import SystemIcon from 'assets/svg/components/SystemIcon';
const imageStyle = { width: '24px', height: '20px' };

const maps: Record<any, ReactElement> = {
  'pay-circle-o': <PayCircleOutlined />,
  'shopping-cart': <ShoppingCartOutlined />,
  'camera-o': <CameraOutlined />,
  'line-chart': <LineOutlined />,
  'code-o': <CodeOutlined />,
  'area-chart': <AreaChartOutlined />,
  'bar-chart': <BarChartOutlined />,
  message: <MessageOutlined />,
  team: <TeamOutlined />,
  dashboard: <DashboardOutlined />,
  api: <ApiOutlined />,
  edit: <EditOutlined />,
  mail: <MailOutlined />,
  voucher: <VoucherIcon imageStyles={imageStyle} />,
  user: <UserIcon imageStyles={imageStyle} />,
  order: <OrderIcon imageStyles={imageStyle} />,
  retrieve: <RetrieveIcon imageStyles={imageStyle} />,
  locker: <LockerIcon imageStyles={imageStyle} />,
  advertising: <Advertising imageStyle={imageStyle} />,
  coin: <CoinIcon imageStyles={imageStyle} />,
  mini: <MiniGameIcon imageStyles={imageStyle} />,
  notification: <NotificationIcon imageStyles={imageStyle} />,
  post: <PostContent imageStyles={imageStyle} />,
  driver: <DriverIcon imageStyles={imageStyle} />,
  system: <SystemIcon imageStyles={imageStyle} />
};

export default maps;

import { RootStore } from './models';
import Router from './router';
import { Provider } from 'react-redux';
import store, { persistor } from 'reduxes';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import viVN from 'antd/lib/locale/vi_VN';

let rootStoreInstance: RootStore;

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider locale={viVN}>
          <Router />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

export function getRootStoreInstance(): RootStore {
  return rootStoreInstance;
}

export default App;

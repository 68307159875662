import React from 'react';

export default function Advertising({ imageStyles }: any) {
  return (
    <span className="anticon anticon-appstore ant-menu-item-icon" style={imageStyles}>
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 0C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H19ZM7.399 5H5.399L2.199 13H4.353L4.753 12H8.043L8.443 13H10.598L7.399 5ZM17 5H15V7H14C13.2191 6.99925 12.4687 7.30302 11.9082 7.84677C11.3477 8.39052 11.0213 9.13139 10.9984 9.91196C10.9755 10.6925 11.2578 11.4513 11.7855 12.027C12.3131 12.6027 13.0444 12.9499 13.824 12.995L14 13H17V5ZM15 9V11H14L13.883 10.993C13.64 10.9641 13.4159 10.847 13.2534 10.664C13.0909 10.481 13.0011 10.2448 13.0011 10C13.0011 9.75524 13.0909 9.51897 13.2534 9.33597C13.4159 9.15296 13.64 9.03591 13.883 9.007L14 9H15ZM6.399 7.885L7.244 10H5.552L6.399 7.885Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}

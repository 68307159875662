import React from 'react';
import { FrownOutlined } from '@ant-design/icons';
import { Page } from 'components';
import './404.scss';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'reduxes/reducers';

function Page404() {
  const userState = useSelector((state: RootState) => state.user);
  if (!userState.isAuth) {
    return <Navigate to="/login" />;
  }

  return (
    <Page inner>
      <div className="error">
        <FrownOutlined />
        <h1>404 Not Found</h1>
      </div>
    </Page>
  );
}

export default Page404;

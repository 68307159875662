import PrimaryLayout from 'layouts/primary-layout';
import { Loader } from 'components';
import { queryLayout } from 'utils';
import config from 'utils/config';
import PublicLayout from 'layouts/public-layout';
import { Navigate, useLocation } from 'react-router-dom';
import './style.scss';
import { observer } from 'mobx-react-lite';
import { useSelector } from 'react-redux';
import { RootState } from 'reduxes/reducers';

const LayoutMap = {
  primary: PrimaryLayout,
  public: PublicLayout
};

function BaseLayout({ children }: any) {
  const location = useLocation();
  const isAuth = useSelector((state: RootState) => state.user.isAuth);
  const loading = useSelector((state: RootState) => state.app.loading);

  const layoutKey = queryLayout(config.layouts, location.pathname);
  if (!isAuth && layoutKey !== 'public') {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // @ts-ignore
  const Container = LayoutMap[layoutKey];

  // const previousPath = ''
  // const currentPath = location.pathname + location.search
  // if (currentPath !== previousPath) {
  //   NProgress.start()
  // }

  // if (!loading.global) {
  //   NProgress.done()
  //   this.previousPath = currentPath
  // }

  return (
    <>
      <Loader fullScreen spinning={loading} />
      <Container>{children}</Container>
    </>
  );
}

export default observer(BaseLayout);

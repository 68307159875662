import { ConfigProvider, Table, TablePaginationConfig } from 'antd';
import './style.scss';

export enum TablePaginationPosition {
  topLeft = 'topLeft',
  topCenter = 'topCenter',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomCenter = 'bottomCenter',
  bottomRight = 'bottomRight'
}

export interface TableRowSelection<T = any> {
  selectedRowKeys?: any[];
  onChange?: (selectedRowKeys: T[], selectedRows: T[]) => void;
}

interface Props {
  tableCSS?: string;
  columns?: any[];
  positionPagination?: TablePaginationPosition[];
  pageSize?: number;
  data: any[];
  scroll?: any;
  rowSelection?: TableRowSelection<any>;
  rowKey?: string;
  currentPage?: number;
  totalPage?: number;
  onTableChange?: (pagination: TablePaginationConfig, filters: any, sorter: any, extra: any) => void;
  loading: boolean;
  rowClassName?: (record: any) => string;
  pagination?: TablePaginationConfig | false;
}
function renderEmptyData() {
  return (
    <div className="empty-data">
      <div className="contain-empty-data-content">
        <span className="contain-empty-data-content-title">Không tìm thấy dữ liệu</span>
        <span>Vui lòng tìm kiếm với bộ lọc khác</span>
      </div>
    </div>
  );
}

function TableComponent({
  tableCSS,
  columns,
  positionPagination = [TablePaginationPosition.bottomRight],
  pageSize = 10,
  data,
  scroll,
  rowSelection,
  rowKey = '_id',
  currentPage,
  totalPage,
  onTableChange,
  loading = false,
  rowClassName
}: Props) {
  return (
    <ConfigProvider componentSize="small" renderEmpty={renderEmptyData}>
      <Table
        rowSelection={rowSelection}
        className={tableCSS}
        columns={columns}
        pagination={false}
        dataSource={data}
        scroll={scroll}
        rowKey={rowKey}
        onChange={onTableChange}
        loading={loading}
        rowClassName={rowClassName}
      />
    </ConfigProvider>
  );
}

export default TableComponent;

import ApiSauce from 'apisauce';
import fileDownload from 'js-file-download';
import { GetListDiscountParams } from 'pages/discount-management/types';
import { GetListOrderParams } from 'pages/order-management/order-list/types';
import { GetListPromotionParams } from 'pages/promotion-management/promotion-list/types';
import storeRedux from 'reduxes';
import { DEFAULT_API } from 'utils/constants';
import { getDateDDMMYYYY } from 'utils/date-helper';

const ExportService = () => {
  const api = ApiSauce.create({
    baseURL: DEFAULT_API,
    responseType: 'blob',
    timeout: 1200000
  });

  async function exportCSVRevoked(params: GetListOrderParams) {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = await api.get('/admin/export-order-revoked', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    await fileDownload(response.data as Blob, `Quản lý thu hồi-${getDateDDMMYYYY(new Date())}.xlsx`);
    return response;
  }

  async function exportCSVVoucherOrder(params: GetListOrderParams) {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = await api.get('/admin/get-order-discount-export', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    await fileDownload(response.data as Blob, `Chi tiết đơn hàng kèm mã Voucher-${getDateDDMMYYYY(new Date())}.xlsx`);
    return response;
  }

  async function exportCSVOrder(params: GetListOrderParams) {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = await api.get('/admin/get-order-export', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    console.log(response);
    if (response.ok) {
      await fileDownload(response.data as Blob, `Quản lý đơn hàng-${getDateDDMMYYYY(new Date())}.xlsx`);
      return response;
    }
  }

  async function exportCSVDiscount(params: GetListDiscountParams) {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = await api.get('/admin/export-excel-promotion-program', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    await fileDownload(response.data as Blob, `Quản lý Voucher-${getDateDDMMYYYY(new Date())}.xlsx`);
    return response;
  }

  async function exportCSVNotificationSample() {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = await api.get('/admin/export-template-users-send-notication', {}, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    await fileDownload(response.data as Blob, `Mẫu Excel - Nhập khách hàng - ${getDateDDMMYYYY(new Date())}.xlsx`);
    return response;
  }

  async function exportTemplatePromotionUser(targetUser: string) {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = await api.get(
      `/admin/export-template-users?targetUser=${targetUser}&chooseUser=${true}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    await fileDownload(response.data as Blob, `Mẫu Excel - Nhập đối tượng - ${getDateDDMMYYYY(new Date())}.xlsx`);
    return response;
  }
  async function exportTemplatePromotionListBox() {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = await api.get(
      '/admin/export-template-boxes',
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    console.log(response?.data);
    await fileDownload(response.data as Blob, `Mẫu Excel - Nhập tủ - ${getDateDDMMYYYY(new Date())}.xlsx`);
    return response;
  }

  async function exportCSVPromotionProgram(params: GetListPromotionParams) {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = await api.get('/admin/export-excel-discount', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    await fileDownload(response.data as Blob, `Quản lý Chương trình khuyến mại-${getDateDDMMYYYY(new Date())}.xlsx`);
    return response;
  }

  async function exportCSVStaff(params: GetListPromotionParams) {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = await api.get('/admin/export-employees', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    await fileDownload(response.data as Blob, `Quản lý Nhân viên-${getDateDDMMYYYY(new Date())}.xlsx`);
    return response;
  }

  async function exportCSVDriverAccount(params: any) {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = await api.get('/admin/export-drivers', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    await fileDownload(response.data as Blob, `Quản lý tài khoản tài xế-${getDateDDMMYYYY(new Date())}.xlsx`);
    return response;
  }


  return {
    exportCSVRevoked,
    exportCSVOrder,
    exportCSVDiscount,
    exportTemplatePromotionUser,
    exportTemplatePromotionListBox,
    exportCSVPromotionProgram,
    exportCSVVoucherOrder,
    exportCSVStaff,
    exportCSVNotificationSample,
    exportCSVDriverAccount
  };
};
export default ExportService;

import { Images } from 'assets/Images';
export default function EditPassWordIcon() {
  return (
    <img
      style={{
        width: 24,
        height: 24
      }}
      src={Images.icEditPassWord}
      alt="EditPassWordIcon"
    />
  );
}

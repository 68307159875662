export enum TARGET_USER {
  USER = 'USER',
  DRIVER = 'DRIVER',
  ALL = 'ALL'
}

export enum CREATED_BY {
  USER = '1',
  DRIVER = '2'
}

export const TargetData = [
  {
    id: CREATED_BY.USER,
    key: 1,
    label: 'Khách hàng',
    index: CREATED_BY.USER
  },
  {
    id: CREATED_BY.DRIVER,
    key: 2,
    label: 'Tài xế',
    index: CREATED_BY.DRIVER
  }
];

export const TargetDataObj = {
  [CREATED_BY.USER]: {
    id: CREATED_BY.USER,
    key: 1,
    label: 'Khách hàng',
    index: CREATED_BY.USER
  },
  [CREATED_BY.DRIVER]: {
    id: CREATED_BY.DRIVER,
    key: 2,
    label: 'Tài xế',
    index: CREATED_BY.DRIVER
  }
};

function formatMoneyServer(value: string) {
  if (typeof value !== 'string') return '';
  return Number(value?.replace(/[.]/g, '')).toString();
}

function formatCurrency(value: string | undefined | number, separate: string = '.'): string {
  if (typeof value === 'number') {
    const stringContainerNumber = value
      .toString()
      .split(/\s*[,\n.]+\s*/)
      .join('');
    return stringContainerNumber.replace(/\B(?=(\d{3})+(?!\d))/g, separate);
  }

  if (typeof value === 'string') {
    const stringContainerNumber = value.split(/\s*[,\n.]+\s*/).join('');
    return stringContainerNumber.replace(/\B(?=(\d{3})+(?!\d))/g, separate);
  }

  return '';
}


function formatNumber(value: any) {
  const formatter=`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return formatter
}


function zeroPad(num: number | string, len = 2) {
  return (Array(len).join('0') + num).slice(-len);
}

export { formatMoneyServer, formatCurrency, zeroPad, formatNumber };

import logoIcon from './ic_logo.png';
import logoText from './logoText.png';
import iconUser from './ic_user.png';
import iconVoucher from './ic_voucher.png';
import iconOrder from './ic_order.png';
import iconArrow from './ic_arrow.png';
import iconToggle from './ic_toggle.png';
import icBack from './ic_back.png';
import icSearch from './ic_search.png';
import icLock from './ic_lock.png';
import icDetail from './ic_detail.png';
import icBoxInfo from './ic_box_info.png';
import icUnLock from './ic_unlock.png';
import icAvatar from './ic_avatar.png';
import icLogOut from './ic_logout.png';
import icLogAvatar from './ic_log.png';
import icClose from './ic_close.png';
import icPass from './ic_pass.png';
import icUnlockNotBag from './ic_unlocknotbag.png';
import icLockNotBag from './ic_locknotbag.png';
import icAddBox from './ic_addbox.png';
import icEditPassWord from './ic_editpass.png';
import icBackDetailUser from './ic_backdetailuser.png';
import icPassError from './ic_passerror.png';
import icToggleIconOpen from './ic_toggle_open.png';
import icOpenDoor from './ic_opendoor.png';
import icCleanDoor from './ic_cleandoor.png';
import icDriverActive from './ic_driveractive.png';
import icDriverInactive from './ic_dieverinactive.png';
import icEditLock from './ic_editlock.png';
import icEyeClosing from './ic_eyeClosing.png';
import icDeleteStatus from './ic_deletestatus.png';
import icOpenDoorUnwrapped from './ic_opendoorunwrap.png';
import icCleanStatus from './ic_cleanstatus.png';
import icEyeHidden from './ic_eyehidden.png';
import icEyeSuccess from './ic_eyesuccess.png';
import icLockSuccess from './lock-success.png';
import icUnlockSuccess from './unlock-success.png';
import icDeleteStatusError from './deletestatus-error.png';
import icDeleteStatusSuccess from './deletestatus-success.png';
import icOpenDoorError from './opendoor-error.png';
import icOpenDoorSuccess from './opendoor-success.png';
import icLockPopUp from './ic_lockPopup.png';
import icOpenDoorDetail from './ic_opendoordetail.png';
import icReloadSuccess from './ic_reloadsuccess.png';
import icReloadError from './ic_reloaderror.png';
import icReload from './ic_reload.png';
import icReloadUnwrapped from './ic_reloadnotwrap.png';
import icDelete from './ic_delete.png';
import icLockLimpid from './ic_lockLimpid.png';
import icLockLimpidList from './ic_LockLimpidList.png';
import icUnlockLimpidList from './ic_UnlockLimpidList.png';
import icBoxLimpidList from './ic_boxLimpidList.png';
import icUnlockLimpid from './ic_UnlockLimpid.png';
import icRevoked from './ic_revoked.png';
import icRevokedSum from './ic_revokedSum.png';
import icRevokedLimpid from './ic_revokedLimpid.png';
import icRetrieve from './ic_retrieve.png';
import icRetrieveOrderLimpid from './ic_retrieveorderLimpid.png';
import icDetailHidden from './ic_detailLimpid.png';
import backGround1 from './image4.jpg';
import backGround2 from './image2.jpg';
import icDeletePromotion from './ic_delete-promotion.png';
import icImportExcel from './ic_import.png';
import icExportExcel from './ic_export.png';
import icDeleteModal from './ic_deletemodal.png';
import icDeleteWhite from './ic_delete_white.png';
import icChangePassBox from './ic_changepassbox.png';
import icActive from './ic_active.png';
import isActiveDisable from './ic_activedisable.png';

export const Images = {
  logoIcon: logoIcon,
  logoText: logoText,
  iconUser: iconUser,
  iconVoucher: iconVoucher,
  iconOrder: iconOrder,
  iconArrow: iconArrow,
  iconToggle: iconToggle,
  iconToggleOpen: icToggleIconOpen,
  icBack: icBack,
  icSearch: icSearch,
  icLock: icLock,
  icDetail: icDetail,
  icBoxInfo,
  icUnLock: icUnLock,
  icAvatar: icAvatar,
  icLogOut: icLogOut,
  icLogAvatar: icLogAvatar,
  icClose: icClose,
  icPass: icPass,
  icUnlockNotBag: icUnlockNotBag,
  icLockNotBag: icLockNotBag,
  icAddBox: icAddBox,
  icEditPassWord: icEditPassWord,
  icBackDetailUser: icBackDetailUser,
  icPassError: icPassError,
  icOpenDoor: icOpenDoor,
  icCleanDoor: icCleanDoor,
  icDriverActive: icDriverActive,
  icDriverInactive: icDriverInactive,
  icEditLock: icEditLock,
  icEyeClosing: icEyeClosing,
  icDeleteStatus: icDeleteStatus,
  icOpenDoorUnwrapped: icOpenDoorUnwrapped,
  icCleanStatus: icCleanStatus,
  icEyeHidden: icEyeHidden,
  icEyeSuccess: icEyeSuccess,
  icLockSuccess: icLockSuccess,
  icUnlockSuccess: icUnlockSuccess,
  icDelete: icDelete,
  icDeleteStatusError: icDeleteStatusError,
  icDeleteStatusSuccess: icDeleteStatusSuccess,
  icOpenDoorError: icOpenDoorError,
  icOpenDoorSuccess: icOpenDoorSuccess,
  icLockPopUp: icLockPopUp,
  icOpenDoorDetail: icOpenDoorDetail,
  icReloadSuccess: icReloadSuccess,
  icReloadError: icReloadError,
  icReload: icReload,
  icReloadUnwrapped: icReloadUnwrapped,
  icLockLimpid: icLockLimpid,
  icLockLimpidList: icLockLimpidList,
  icUnlockLimpidList: icUnlockLimpidList,
  icBoxLimpidList: icBoxLimpidList,
  icUnlockLimpid: icUnlockLimpid,
  icRevoked: icRevoked,
  icRevokedSum: icRevokedSum,
  icRevokedLimpid: icRevokedLimpid,
  icRetrieve: icRetrieve,
  icRetrieveOrderLimpid: icRetrieveOrderLimpid,
  icDetailHidden: icDetailHidden,
  backGround1: backGround1,
  backGround2: backGround2,
  icDeletePromotion: icDeletePromotion,
  icImportExcel: icImportExcel,
  icExportExcel: icExportExcel,
  icDeleteModal: icDeleteModal,
  icDeleteWhite: icDeleteWhite,
  icChangePassBox: icChangePassBox,
  icActive: icActive,
  isActiveDisable: isActiveDisable,
};

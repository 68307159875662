import moment, { Moment } from 'moment';
import { zeroPad } from './helperFunc';

const TimeForm = {
  dateTimeSearch: 'YYYY-MM-DD',
  dateTime: 'DD/MM/YYYY HH:mm',
  timeDate: 'HH:mm:ss DD/MM/YYYY',
  timeStatus: 'HH:mm:ss - DD/MM/YYYY'
};

export enum MOMENT_FORMATS {
  DATE_TIME_SECOND = 'HH:mm:ss - DD/MM/YYYY',
  DATE_TIME = 'HH:mm - DD/MM/YYYY',
  DATE_SPACE_TIME = 'DD/MM/YYYY HH:mm',
  DATE = 'DD/MM',
  FULL_DATE = 'DD/MM/YYYY',
  YEAR = 'YYYY',
  MONTH = 'MMM',
  MONTH_FULL = 'MMMM',
  YEAR_MONTH = 'YYYY-MM',
  DAY_MONTH = 'DD MMM',
  HOUR_MINUTE_SECONDS = 'HH:mm:ss',
  TIME = 'HH:mm'
}

export function getFormat(date: string, format: MOMENT_FORMATS) {
  const currentTime = moment(date);
  if (!currentTime.isValid()) {
    return '---';
  }

  return currentTime.format(format);
}

function postTime(data: any) {
  return moment(data).toISOString();
}

function splitDateAndTime(date: string) {
  if (!moment(date).isValid()) {
    return ['', ''];
  }

  return moment(date).format(TimeForm.dateTime).split(' ');
}

// convert Date to YYYY-MM-DD format
export const getDateYYYYMMDDDash = (date: Date | string | Moment): string => {
  return date ? moment(date).format('YYYY-MM-DD') : '';
};

export const getISOString = (date: Date | string | Moment): string | undefined => {
  const currentDate = moment(date);
  return currentDate.isValid() ? currentDate.toISOString() : undefined;
};

// convert Date to DD/MM/YYYY format
export const getDateDDMMYYYY = (date?: Date | string): string => {
  return date ? moment(date).format('DD/MM/yyyy') : '';
};

// convert Date to DD-MM
export const getDateDDMM = (date?: Date): string | undefined => {
  return date ? moment(date).format('DD-MM') : undefined;
};

// convert Date to DD-MM-yyyy
export const getDateDDMMyyyyWithDash = (date?: Date): string | undefined => {
  return date ? moment(date).format('DD-MM-yyyy') : undefined;
};

// convert Date to MM/yyyy
export const getDateMMYYYY = (date?: Date | string): string | undefined => {
  return date ? moment(date).format('MM/yyyy') : undefined;
};

export const getDateTimeHHmmDDMMYYYY = (date?: Date | string, placeholder: string = ''): string => {
  const currentDate = moment(date);
  return currentDate.isValid() ? currentDate.format('HH:mm:ss - DD/MM/yyyy') : placeholder;
};

export const getDateTimeHHmmssEnterDDMMYYYY = (date?: Date | string, placeholder = ['', '']): string[] => {
  const currentDate = moment(date);
  return currentDate.isValid() ? currentDate.format('HH:mm:ss DD/MM/YYYY').split(' ') : placeholder;
};

export const getDateTimeDDMMYYYYEnterHHmmss = (date?: Date | string, placeholder = ['']): string[] => {
  const currentDate = moment(date);
  return currentDate.isValid() ? currentDate.format('YYYY-MM-DD HH:mm:ss').split('  ') : placeholder;
};

export const HHmmss = (date?: Date | string, placeholder = ['']): string[] => {
  const currentDate = moment(date);
  return currentDate.isValid() ? currentDate.format('HH:mm:ss').split('  ') : placeholder;
};

export const getDateTimeHHmm = (date?: Date | string | number): string => {
  return date ? moment(date).format('HH:mm') : '';
};

// convert second to mm:ss
export const getTimeMMss = (second: number) => {
  return second >= 0 ? `${moment.utc(second * 1000).format('mm:ss')}` : '';
};

export function getDiffTimeString(start: string | Date | number, end: string | Date | number) {
  const startTime = moment(start);
  const endTime = moment(end);
  const hours = endTime.diff(startTime, 'hours');
  const minutes = endTime.diff(startTime, 'minutes') % 60;
  const seconds = endTime.diff(startTime, 'seconds') % 60;

  return {
    hours: zeroPad(hours),
    minutes: zeroPad(minutes),
    seconds: zeroPad(seconds),
    isPositive: hours >= 0 && minutes >= 0 && seconds >= 0,
    hoursNum: hours
  };
}

export default { TimeForm, getFormat, postTime, splitDateAndTime };

import ApiSauce from 'apisauce';
import { DEFAULT_API } from 'utils/constants';
import { getFileNameByPath, responseTransform } from './api-helper';
import storeRedux from 'reduxes';
import fileDownload from 'js-file-download';
import { getDateDDMMYYYY } from 'utils/date-helper';

export const TYPE_RETURN = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const MAX_SIZE_UPLOAD_FILE = 20 * 1024 * 1024;
const UploadServices = () => {
  const api = ApiSauce.create({
    baseURL: DEFAULT_API,
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data'
    },
    timeout: 2000000,
    responseType: 'blob'
  });

  const uploadExcelUsers = async (file: any, _id: string, targetUser: string) => {
    const accessToken = storeRedux.getState().user.accessToken;

    const form = new FormData();

    form.append('files', file);
    form.append('_id', _id);
    form.append('targetUser', targetUser);
    form.append('chooseUser', 'true');
    const response = await api.post(`/admin/import-template-users`, form, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  };

  const uploadExcelBoxes = async (file: any, _id: string) => {
    const accessToken = storeRedux.getState().user.accessToken;

    const form = new FormData();

    form.append('files', file);
    form.append('_id', _id);

    const response = await api.post(`/admin/import-template-boxes`, form, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  };

  const uploadExcelUsersNoti = async (file: any, _id: string) => {
    const accessToken = storeRedux.getState().user.accessToken;

    const form = new FormData();

    form.append('files', file);
    form.append('_id', _id);
    const response = await api.post(`/admin/import-template-users-send-notication`, form, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  };

  return {
    uploadExcelUsers,
    uploadExcelBoxes,
    uploadExcelUsersNoti
  };
};
export default UploadServices;

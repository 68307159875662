import { Images } from 'assets/Images';

export default function EyeHidden() {
  return (
    <img
      style={{
        width: 56,
        height: 56
      }}
      src={Images.icEyeHidden}
      alt="PassIcon"
    />
  );
}

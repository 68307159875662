import { DISCOUNT_VIEW_MODE } from 'pages/discount-management/components/add-discount/types';
import { ActionBody } from 'reduxes/types';
import { DefaultActionTypes, createActions, createReducer } from 'reduxsauce';
import SeamlessImmutable from 'seamless-immutable';
import { State, DiscountManagementActionCreators, SetCurrentDiscount, Action, SetDiscountViewMode } from './types';

export type DiscountManagementState = State;

const INITIAL_STATE = SeamlessImmutable<State>({
  currentDiscount: {},
  viewMode: DISCOUNT_VIEW_MODE.ADD
});

export interface DiscountManagementActionTypes extends DefaultActionTypes {
  SET_CURRENT_DISCOUNT: 'SET_CURRENT_DISCOUNT';
  SET_DISCOUNT_VIEW_MODE: 'SET_DISCOUNT_VIEW_MODE';
}

const { Types, Creators } = createActions<DiscountManagementActionTypes, DiscountManagementActionCreators>({
  setCurrentDiscount: ['currentDiscount'],
  setDiscountViewMode: ['viewMode']
});

export const DiscountManagementType = Types;

export default Creators;

const setCurrentDiscount: ActionBody<SetCurrentDiscount> = (state, { currentDiscount }) => {
  return { ...state, currentDiscount };
};

const setDiscountViewMode: ActionBody<SetDiscountViewMode> = (state, { viewMode }) => {
  return { ...state, viewMode };
};

export const reducer = createReducer<State, Action>(INITIAL_STATE, {
  [Types.SET_CURRENT_DISCOUNT]: setCurrentDiscount,
  [Types.SET_DISCOUNT_VIEW_MODE]: setDiscountViewMode
});

import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const BoxManagement = lazy(() => import('./box-list/index'));
const AddBox = lazy(() => import('./add-box'));
const BoxDetail = lazy(() => import('./box-detail'));

const routes = [
  {
    identifier: FUNCTION_WEB.BOX_MANAGEMENT,
    path: '/box-management',
    element: BoxManagement
  },
  {
    identifier: FUNCTION_WEB.ADD_BOX_MANAGEMENT,
    path: '/box-management/add-box',
    element: AddBox
  },
  {
    identifier: FUNCTION_WEB.BOX_MANAGEMENT_DETAIL,
    path: '/box-management/:_id',
    element: BoxDetail
  }
];

export default routes;

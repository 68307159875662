import { combineReducers } from 'redux';
import { AppState } from './app-reducer';
import { DiscountManagementState } from './discount-management-reducer';
import { OrderManagementState } from './order-management-reducer';
import { UserManagementState } from './user-management-reducer';
import { UserState } from './user-reducer';
import { BoxManagementState } from './box-management-reducer';

export interface RootState {
  user: UserState;
  userManagement: UserManagementState;
  app: AppState;
  discountManagement: DiscountManagementState;
  orderManagement: OrderManagementState;
  boxManagement: BoxManagementState;
}

const rootReducer = combineReducers<RootState>({
  user: require('./user-reducer').reducer,
  app: require('./app-reducer').reducer,
  userManagement: require('./user-management-reducer').reducer,
  discountManagement: require('./discount-management-reducer').reducer,
  orderManagement: require('./order-management-reducer').reducer,
  boxManagement: require('./box-management-reducer').reducer
});

export default rootReducer;

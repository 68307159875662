import { Images } from 'assets/Images';

export default function PassIcon() {
  return (
    <img
      style={{
        width: 56,
        height: 56
      }}
      src={Images.icPass}
      alt="PassIcon"
    />
  );
}

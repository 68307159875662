import { Layout } from 'antd';
import { Images } from 'assets/Images';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'reduxes/reducers';
import './style.scss';
import RightContent from './RightContent';

function Header({ fixed, collapsed }: any) {
  const user = useSelector((state: RootState) => state.user.user);

  return (
    <Layout.Header
      className={classNames('header', {
        fixed,
        collapsed
      })}
    >
      <div className="right-container">
        <span className="email-scss">{user?.username}</span>
        <div className="avatar-envelop">{<img className="avatar-default" src={user?.avatar ? user?.avatar : Images.icAvatar} alt="Avatar" />}</div>
        <RightContent />
      </div>
    </Layout.Header>
  );
}

export default Header;

export default function PostContent({ imageStyles }: any) {
  return (
    <span className="anticon ant-menu-item-icon" style={imageStyles}>
      <svg width={17} height={20} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 0H15.5C16.0523 0 16.5 0.447715 16.5 1V19C16.5 19.5523 16.0523 20 15.5 20H1.5C0.947715 20 0.5 19.5523 0.5 19V1C0.5 0.447715 0.947715 0 1.5 0ZM4.5 12C3.94772 12 3.5 12.4477 3.5 13C3.5 13.5523 3.94772 14 4.5 14H11.5C12.0523 14 12.5 13.5523 12.5 13C12.5 12.4477 12.0523 12 11.5 12H4.5ZM3.5 16C3.5 15.4477 3.94772 15 4.5 15H8C8.55228 15 9 15.4477 9 16C9 16.5523 8.55228 17 8 17H4.5C3.94772 17 3.5 16.5523 3.5 16ZM4.5 3C3.94772 3 3.5 3.44772 3.5 4V9C3.5 9.55228 3.94772 10 4.5 10H11.5C12.0523 10 12.5 9.55228 12.5 9V4C12.5 3.44772 12.0523 3 11.5 3H4.5ZM5.5 8V5H10.5V8H5.5Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}

import { Tooltip } from 'antd';
import Unlock from 'assets/imgs/Unlock.svg';
import { IconProps, IconSize, IconStyles } from 'types/icon';

export default function UnlockIcon({ size = IconSize.DEFAULT, onClick, className }: IconProps) {
  return (
    <Tooltip title="Mở">
      <img
        src={Unlock}
        alt="Unlock"
        style={{
          width: size,
          height: size,
          ...IconStyles.default
        }}
        onClick={onClick}
        className={className}
      />
    </Tooltip>
  );
}

import { Tooltip } from 'antd';
import EyeHide from 'assets/imgs/EyeHide.svg';
import { IconProps, IconSize, IconStyles } from 'types/icon';

function EyeHideIcon({ size = IconSize.DEFAULT, className, onClick }: IconProps) {
  return (
    <Tooltip title="Ẩn">
      <img
        src={EyeHide}
        alt="Edit"
        style={{
          width: size,
          height: size,
          ...IconStyles.default
        }}
        className={className}
        onClick={onClick}
      />
    </Tooltip>
  );
}

export default EyeHideIcon;

import { Images } from 'assets/Images';

export default function EyeSuccess() {
  return (
    <img
      style={{
        width: 56,
        height: 56
      }}
      src={Images.icEyeSuccess}
      alt="EyeSuccess"
    />
  );
}

import classNames from 'classnames';
import Loader from 'components/loader';
import { ReactNode } from 'react';
import './style.scss';

interface Props {
  className?: string;
  children: ReactNode;
  loading?: boolean;
  inner?: boolean;
}

function Page({ className, children, loading = false, inner = false }: Props) {
  const loadingStyle = {
    height: 'calc(100vh - 165px)',
    overflow: 'hidden'
  };

  return (
    <div
      className={classNames('page', className, {
        'content-inner': inner
      })}
      style={loading ? loadingStyle : undefined}
    >
      {loading ? <Loader spinning /> : undefined}
      {children}
    </div>
  );
}

export default Page;

import React from 'react';
export default function RetrieveIcon({ imageStyles }: any) {
    return (
        <span className="anticon ant-menu-item-icon">

            <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg"style={imageStyles} className="ant-menu-item-icon">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 18V2.8L2.3 0H15.7L18 2.8V18H0ZM2.4 3H15.6L14.75 2H3.25L2.4 3ZM9 15L13 11L11.6 9.6L10 11.2V7H8V11.2L6.4 9.6L5 11L9 15Z"
                    fill="currentColor" />
            </svg>
        </span>
    );
}

import React from 'react';

export default function DotIcon({ imageStyles }: any) {
  return (
    <span className="anticon ant-menu-item-icon" style={imageStyles}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2963_28960)">
          <path
            d="M22.1113 6.61631L14.6113 2.49131C14.5005 2.43038 14.3762 2.39844 14.2498 2.39844C14.1234 2.39844 13.999 2.43038 13.8883 2.49131L6.38827 6.61631C6.27068 6.68105 6.17262 6.77617 6.10432 6.89173C6.03603 7.0073 6 7.13907 6 7.27331C6 7.40754 6.03603 7.53932 6.10432 7.65488C6.17262 7.77045 6.27068 7.86557 6.38827 7.93031L13.4998 11.8416V19.8808L11.8793 18.9894C11.5167 18.79 11.061 18.9221 10.8614 19.2847V19.2847C10.6617 19.6474 10.794 20.1034 11.1568 20.3029L13.8883 21.8053C13.999 21.8664 14.1233 21.8984 14.2498 21.8984C14.3762 21.8984 14.5006 21.8664 14.6113 21.8053L22.1113 17.6803C22.2289 17.6156 22.327 17.5205 22.3953 17.4049C22.4637 17.2894 22.4997 17.1576 22.4998 17.0233V7.27331C22.4997 7.13905 22.4637 7.00726 22.3953 6.89169C22.327 6.77613 22.2289 6.68102 22.1113 6.61631ZM14.2498 4.00481L20.1935 7.27331L14.2498 10.5418L8.30602 7.27331L14.2498 4.00481ZM20.9998 16.5801L14.9998 19.8801V11.8408L20.9998 8.54081V16.5801Z"
            fill="currentColor"
          />
          <path
            d="M7.5 11.25C7.5 11.6642 7.16421 12 6.75 12H2.25C1.83579 12 1.5 11.6642 1.5 11.25V11.25C1.5 10.8358 1.83579 10.5 2.25 10.5H6.75C7.16421 10.5 7.5 10.8358 7.5 11.25V11.25Z"
            fill="currentColor"
          />
          <path
            d="M9 17.25C9 17.6642 8.66421 18 8.25 18H3.75C3.33579 18 3 17.6642 3 17.25V17.25C3 16.8358 3.33579 16.5 3.75 16.5H8.25C8.66421 16.5 9 16.8358 9 17.25V17.25Z"
            fill="currentColor"
          />
          <path
            d="M10.5 14.25C10.5 14.6642 10.1642 15 9.75 15H5.25C4.83579 15 4.5 14.6642 4.5 14.25V14.25C4.5 13.8358 4.83579 13.5 5.25 13.5H9.75C10.1642 13.5 10.5 13.8358 10.5 14.25V14.25Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_2963_28960">
            <rect width="24" height="24" fill="currentColor" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

import { SetIsAddUserSuccess, State, UserManagementActionCreators, Action, SetCurrentUser } from './types';
import Immutable from 'seamless-immutable';
import { createActions, createReducer, DefaultActionTypes } from 'reduxsauce';
import { ActionBody } from 'reduxes/types';

export type UserManagementState = State;

export const INITIAL_STATE = Immutable<State>({
  isAddUserSuccess: false,
  currentUser: {
    _id: ''
  }
});

export interface UserManagementActionTypes extends DefaultActionTypes {
  SET_IS_ADD_USER_SUCCESS: 'SET_IS_ADD_USER_SUCCESS';
  SET_CURRENT_USER: 'SET_CURRENT_USER';
}

const { Types, Creators } = createActions<UserManagementActionTypes, UserManagementActionCreators>({
  setIsAddUserSuccess: ['isAddUserSuccess'],
  setCurrentUser: ['currentUser']
});

export const UserManagementType = Types;

export default Creators;

const setIsAddUserSuccess: ActionBody<SetIsAddUserSuccess> = (state, { isAddUserSuccess }) => ({
  ...state,
  isAddUserSuccess
});

const setCurrentUser: ActionBody<SetCurrentUser> = (state, { currentUser }) => {
  return {
    ...state,
    currentUser
  };
};

export const reducer = createReducer<State, Action>(INITIAL_STATE, {
  [Types.SET_IS_ADD_USER_SUCCESS]: setIsAddUserSuccess,
  [Types.SET_CURRENT_USER]: setCurrentUser
});

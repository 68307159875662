import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from 'layouts';
import routes from './routes';
import { Loader } from 'components';
import Page404 from 'pages/error/404';
import PermissionRoute from './permission-route';
import { useSelector } from 'react-redux';
import { RootState } from 'reduxes/reducers';

export default function Router() {
  const userName = useSelector((state: RootState) => state.user.user.username);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader spinning fullScreen />}>
        <Routes>
          <Route element={<Layout />}>
            {routes.map((item: any) => {
              return (
                <Route
                  key={item.path}
                  path={item.path}
                  element={
                    <PermissionRoute role={userName} identifier={item.identifier} path={item.path}>
                      <item.element />
                    </PermissionRoute>
                  }
                />
              );
            })}
            <Route key="page404" path="*" element={<Page404 />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

import apisauce from 'apisauce';
import { DEFAULT_API } from 'utils/constants';
import { SignInBody } from './api-types';
import { ActiveUserParams, GetListUserBody } from 'pages/user-management/user-list/types';
import { BaseGetListRequest } from 'pages/user-management/types';
import { GetUserDetailRequest, UpdateUserRequest } from 'pages/user-management/user-detail/types';
import { LockUserRequest } from 'pages/user-management/components/lock-user/types';
import { CreatePromotionDiscount, GetListDiscountBody, GetListDiscountParams, PromotionProgramModelInterface } from 'pages/discount-management/types';
import { CreateDiscountParams, UpdateDiscountParams, UpdateDiscountStatusParams } from 'pages/discount-management/components/add-discount/types';
import { GetListOrderParams, UpdateOrderStatusParams } from 'pages/order-management/order-list/types';
import { AddBoxRequestDTO, AdminGetBoxListBody, ChangePasswordRequestDTO, UpdateBoxRequestDTO } from 'pages/box-management/types';
import { AddBoxSlotRequestDTO, BOX_SLOT_STATUS, GetBoxSlotListRequestDTO, UpdateBoxSlotRequestDTO } from 'pages/box-slot-management/types';
import storeRedux from 'reduxes';
import { responseTransform } from './api-helper';
import { SearchPriceBox } from 'pages/price-management/price-list/types';
import { AddBannerAdvertising, UpdateBannerAddverising } from 'pages/advertising-management/types';
import { GetListBanner } from 'pages/advertising-management/advertising-list/types';
import { Banner_Box_List, CHANGE_STATUS_BANNER } from 'pages/advertising-management/components/DetailsBanner/types';
import { GetListPromotionParams } from 'pages/promotion-management/promotion-list/types';
import { GetListOrganizationParams } from 'pages/department-management/types';
import { EmployeeModelInterface } from 'pages/staff-management/types';
import { MiniGameModelInterface } from 'pages/mini-game-management/types';

const api = apisauce.create({
  baseURL: DEFAULT_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
  timeout: 10000
});
// transform response
api.addResponseTransform(responseTransform);

function CreateApi() {
  function signIn(body: SignInBody) {
    const response = api.post<any>('/admin/sign-in', body);
    return response;
  }

  function signOut() {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(
      '/signout',
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  // change password log in
  function changePassWord(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post<any>('/admin/change-password', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  //Get permission of current login
  function getListUserPermission(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get<any>('/admin/detail-employee-group-permission', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }
  // manager banner
  function getBanner(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get<any>('/banners', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function deleteBanner(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post<any>('/delete-banner', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function inactiveBanner(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post<any>('/inactivate-banner', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  // manager advertising
  function getAdvertising(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get<any>('/advertisements', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function deleteAdvertising(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post<any>('/delete-advertisement', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function inactiveAdvertising(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post<any>('/inactivate-advertisement', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  // manager partner
  function getPartner(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get<any>('/partners', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getPartnerDetail(id: string): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get<any>(
      '/partner-detail',
      {
        _id: id
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }

  function deletePartner(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post<any>('/delete-partner', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function deactivatePartner(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post<any>('/deactivate-partner', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function createPartner(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post<any>('/create-partner', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function updatePartner(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post<any>('/update-partner', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  // manage user
  function getUserDetail(params: GetUserDetailRequest): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/user-info', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getListUser(params: GetListUserBody | BaseGetListRequest): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get<any>('/get-list-user', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getAllUsers(): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      '/get-all-users',
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  function createUser(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post<any>('/signup', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function updateUser(params: UpdateUserRequest): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/update-user-web-admin', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function inactiveUser(params: LockUserRequest): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/inactive-user', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function activeUser(params: ActiveUserParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/unlock-user', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function activeCodeUser(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/approve-user', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  //create password in user detail
  function createPassWord(data: string): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(
      `/send-mail-generate-code/${data}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  //discount promotion
  function createDiscountPromotion(body: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/create-promotion-program', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function updateDiscountPromotion(body: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/update-promotion-program', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function generateDiscountCode(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/generate-promotion-program-code', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function discountDeatail(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/get-promotion-program-detail', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function deleteVoucher(body: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(
      '/admin/delete-promotion-program',
      { _id: body },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  function inactiveVoucher(_id: string): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(
      `/admin/inactivate-promotion-program`,
      { _id: _id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  // discount
  function getListDiscountAdmin(params: GetListDiscountParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/get-promotion-program-list', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function createDiscount(params: CreateDiscountParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/create-discount', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function updateDiscount(params: UpdateDiscountParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/update-discount', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function updateDiscountStatus(params: UpdateDiscountStatusParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/update-discount-status', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  //organization
  function getListOrganization(params: GetListOrganizationParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get<any>('/admin/get-list-organization', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getListOrganizationParent(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get<any>('/admin/organizations', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getDetailOrganization(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get<any>('/admin/detail-organization', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function createOrganization(params: GetListOrganizationParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/create-organization', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function updateOrganization(params: GetListOrganizationParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/update-organization', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function deleteDepartment(body: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(
      '/admin/delete-organization',
      { idOrg: body },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  // user role
  function getListUserRole(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/user-role-list', params, { headers: { Authorization: `Bearer ${accessToken}` } });
    return response;
  }

  // partner
  function getListPartner(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/all-partner-list', params, { headers: { Authorization: `Bearer ${accessToken}` } });
    return response;
  }

  // order
  function getListOrder(params: GetListOrderParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/order-list-admin', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  //order revoked
  function getListOrderRevoked(params: GetListOrderParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('admin/order-revoked-list', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function updateOrderStatus(params: UpdateOrderStatusParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/update-order-status', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function orderDetail({ code }: { code: string }): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      'admin/order-detail',
      { code },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  function getAllOrders(): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/get-all-orders', {}, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function endOrders(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(
      `/admin/end-order?_id=${params}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }

  function orderRevoked(code: string): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(
      `admin/revoked-order`,
      { code: code },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }

  // promotion
  function getListPromotion(params: GetListPromotionParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/promotion-programs', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getPromotionDetail(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/detail', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function promotionHidden(_id: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(
      `/admin/inactivate-discount`,
      { _id: _id },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }

  function updatePromotion(body: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/update-promotion-program`, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function deletePromotion(body: Array<any>): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(
      '/admin/delete-discount',
      { ids: body },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  // box
  function getBoxList(query: AdminGetBoxListBody): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/get-box-list', query, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function getBoxDetail(_id: string): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`/boxes/detail/${_id}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function toggleBoxStatus(boxId: string): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/toggle-box-status', { boxId }, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function updateBox(id: string, body: UpdateBoxRequestDTO): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.put(`/boxes/${id}`, body, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function addBox(body: AddBoxRequestDTO): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/boxes', body, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function getBoxTemplate(typeBox: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`/admin/get-box-template?type=${typeBox}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function deleteBoxes({ _id }: { _id: Array<string | any> }): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/boxes/delete-slot', { _id }, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  // box-slot
  function getBoxSlotList(query: GetBoxSlotListRequestDTO): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/boxes/get-list-slot', query, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function getAllBoxes(): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/get-all-boxes', {}, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function toggleLockBox(body: { slotId: string[]; status: BOX_SLOT_STATUS.AVAILABLE | BOX_SLOT_STATUS.LOCK }): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/boxes/slot/update-status`, body, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function openDoor(body: { slotId: string[] }): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/boxes/unlock-door-remote`, body, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function resetStatusBox(body: { slotId: string[] }): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(
      `/boxes/slot/clear`,
      {
        slotId: body.slotId,
        status: BOX_SLOT_STATUS.AVAILABLE
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    return response;
  }

  function getBoxSlotDetail(_id: string): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`boxes/${_id}/detail-slot`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function updateBoxSlot(_id: string, body: UpdateBoxSlotRequestDTO): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/boxes/${_id}/update-slot`, body, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function addBoxSlot(boxId: string, body: AddBoxSlotRequestDTO): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/boxes/${boxId}/slot`, body, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  // city-district-area
  function getAllCity(): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/get-list-city', {}, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function getListDistrict(cityId: Array<string | undefined>): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/get-list-district', { cityId }, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function getListWard(wardId: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`/get-list-ward?districtId[]=${wardId[0]}`, {}, { headers: { Authorization: `Bearer ${accessToken}` } });
    return response;
  }

  //start excel export
  function getListUserExcel(params: GetListUserBody | BaseGetListRequest): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get<any>('/admin/get-user-export', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getListBoxExcel(query: AdminGetBoxListBody): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/get-box-export', query, {
      headers: { Authorization: `Bearer ${accessToken}` }
    });

    return response;
  }

  function getListOrderExcel(params: GetListOrderParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.get('/admin/get-order-export', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getListRevokedExcel(params: GetListOrderParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.get('/admin/export-order-revoked', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getListBannerExcel(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.get('/admin/export-banners', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getListDriverExcel(): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.get(
      'admin/export-driverkyc',
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }
  // end excel export

  // update price box
  function updatePriceBox(params: SearchPriceBox): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.post('/admin/update-config', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getListHistoryPrice(params: SearchPriceBox): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/admin/get-configs?page=${params.page}&pageSize=${params.pageSize}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  function searchListHistoryPrice(params: SearchPriceBox): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`/admin/get-configs`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getAllBannerAdvertising(params: GetListBanner): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`/admin/banners`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getBannerDetail(_id: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/admin/banner-detail?_id=${_id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }

  function getOtherList(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/get-other-list?types[]=${params}&types[]=0`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  function getPartnerAdvertissing(): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/partners`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }
  function addBannerAdvertising(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/create-banner`, params, { headers: { Authorization: `Bearer ${accessToken}` } });

    return response;
  }

  function updateBannerAdvertising(body: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/update-banner', body, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getBannerBoxList(params: Banner_Box_List): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`/admin/banner-get-box-list`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getArticles(param: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`/admin/articles`, param, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function inactivateBanner(params: CHANGE_STATUS_BANNER): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/inactivate-banner`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function searchArticles(param: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`/articles`, param, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }
  function getArticleDetail(id: string): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/admin/article-detail?_id=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }
  function addArticle(body: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/create-article`, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }
  function updateArticle(id: string, body: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/update-article?articlId=${id}`, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }
  function deleteArticle(body: Array<string>): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(
      `/admin/delete-article`,
      { ids: body },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }
  function inactiveArticle(param: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/inactivate-article`, param, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getListArticleExcel(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.get('/admin/export-articles', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getListDriver(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.get('/admin/get-driverkyc', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getDriverDetail(id: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.get(
      `/admin/driverkyc-detail?_id=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  function approveDriverkyc(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.post(`/admin/approve-driverkyc`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  // reboot box
  function rebootBox(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.post(`/boxes/reboot-box`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  // discount
  function getListDiscount(param: GetListPromotionParams): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/discounts', param, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function getListVoucher(): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      '/admin/get-promotion-program-list',
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }
  //list box in discount
  function getListBoxDiscount(param?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/discount-get-box-list', param, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function getListUsersDiscount(param?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/discount-get-user-list', param, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function getListBoxDiscountDetail(param?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/detail-discount-box?', param, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function getListUserDiscountDetail(param?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/detail-discount-user?', param, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  //update list box discount
  function updateListBoxDiscount(id: string, params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.post(`/admin/update-discount-box?_id=${id}`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function updateListUsersDiscount(id: string, params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.post(`/admin/update-discount-user?_id=${id}`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function deleteBoxesDiscount(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/delete-discount-box`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function deleteUsersDiscount(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/delete-discount-user`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function updateDiscountAdmin(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/update-discount`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function updateTargetUserDiscount(id: string, params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.post(`/admin/update-discount-user?_id=${id}`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function getListVoucherSelect(): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      '/admin/get-voucher-discount',
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }
  function getListPartnerSelect(): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      '/admin/get-partner-discount',
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }
  function checkTargetUser(idDiscount: string, targetUser: string): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/admin/check-target-user?idDiscount=${idDiscount}&targetUser=${targetUser}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }
  function confirmChangeTargetUser(idDiscount: string): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/admin/oke-target-user?idDiscount=${idDiscount}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  // reboot box
  function changePasswordBox(params: ChangePasswordRequestDTO): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;

    const response = api.post(`/boxes/change-password-box`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  // staff
  function getListStaff(params: EmployeeModelInterface): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get<any>('/admin/employees', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getStaffDetail(param?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`/admin/detail-employee`, param, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function createEmployee(params?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/create-employee', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function createPassWordEmployee(params?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/send-mail-password?`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getListNotification(params?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/admin/send-notications?page=${params?.page}&pageSize=${params?.pageSize}&keyword=${params?.title}&status=${params?.status}&startTime=${params?.startTime}&endTime=${params?.endTime}&targetUser=${params?.targetUser}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  function getCreateNotification(body: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/create-send-notication`, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function getDetailNotification(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/admin/detail-send-notications?_id=${params}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }
  function getUserListNotification(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`/admin/get-send-notication-phone`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function updateSendNotificationPhone(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/update-send-notication-phone`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  

  function updateSendNotification(params: any): Promise<any> {
    console.log('PARAMS API', params);
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/update-send-notication`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function updateStaff(params?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/update-employee', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function detailSendNotificationPhone(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/admin/detail-send-notication-phone?`,
      params,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }

  function deleteUserSendNotification(params: any) {
    console.log('DELETE', params);
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/delete-send-notication-phone`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }
  function activeStaff(params?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/lock-employee', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function importNotificationExcel(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/import-template-users-send-notication`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  //permission

  function getListPermission(param?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/group-employees', param, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function exportNotificationExcel(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`/admin/export-template-users-send-notication`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }
  function createGroupEmployee(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/create-group-employee', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function getListMiniGame(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    console.log('paramsAPI', params);
    const response = api.get(
      `/admin/mini-game-configs?page=${params.page}&pageSize=${params.pageSize}&keyword=${params.keyword}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }
  function getDetailsGiftMiniGame(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/admin/minigame/detail-gift?_id=${params}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }

  function updateMiniGame(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/update-mini-game-config`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function updateGiftMiniGame(params: any): Promise<any> {
    console.log('PARAMS UPDATE', params);
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/update-mini-game-gift`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function createMiniGame(params: MiniGameModelInterface) {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/create-mini-game-config`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getListUserEmployee(param?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/list-employee', param, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getListGiftMiniGame(params: any) {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/admin/detail-mini-game-gift?_id=${params._id}&page=${params.page}&pageSize=${params.pageSize}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }

  function getDetailMiniGame(params: any) {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/admin/detail-mini-game-config?_id=${params}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }

  function createGiftMiniGame(params: any) {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/create-mini-game-gift`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getListResultMiniGame(params: any) {
    const accessToken = storeRedux.getState().user.accessToken;
    console.log('PARAMS SEARCH', params);
    const response = api.get(
      `/admin/list-mini-game-results?boxId=${params?.boxId}&keyword=${params?.keyword}&gameConfigId=${params?.gameConfigId}&page=${params?.page}&pageSize=${params?.pageSize}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }
  function updateResultMiniGame(params: any) {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/update-mini-game-result`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function deleteMiniGame(params: any) {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/delete-mini-game-config`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function deleteGiftMiniGame(params: any) {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/delete-mini-game-gift`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getListTurnUser(params: any) {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(
      `/admin/detail-mini-game-user-turn?gameConfigId=${params?.gameConfigId}&keyword=${params?.keyword}&page=${params?.page}&pageSize=${params?.pageSize}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    return response;
  }
  function updateAddTurnUser(params: any) {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/setup-mini-game-user-turn`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function updateListUsersPermission(id: string, params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/update-employees?_id=${id}`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getListUserPermissionDetail(param?: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/detail-employees?', param, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function deleteEmployeePermission(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/delete-employees`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function getPermissionDetail(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/detail-group-employee', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function inactivePermission(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/inactive-group-employee', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function updateGroupEmployee(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/update-group-employee`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function detailGroupPermission(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get(`/admin/detail-group-permission`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function createGroupPermission(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/create-group-permission`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  function resentSendNotication(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(`/admin/resent-send-notication`, params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }

  // driver account

  function getListDriverAccount(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get<any>('/admin/drivers', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return response;
  }


  function getDriverAccountDetail(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.get('/admin/detail-driver', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function inactiveDriverAccount(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/lock-driver', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }


  function updateDriverAccountStatus(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/lock-driver', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function updateDriverAccount(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post('/admin/update-driver', params, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    return response;
  }

  function createPassWordDriver(params: any): Promise<any> {
    const accessToken = storeRedux.getState().user.accessToken;
    const response = api.post(
      `/admin/generate-password-driver`,
      params,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response;
  }


  return {
    getListNotification,
    getCreateNotification,
    getDetailNotification,
    getUserListNotification,
    updateSendNotificationPhone,
    detailSendNotificationPhone,
    deleteUserSendNotification,
    importNotificationExcel,
    exportNotificationExcel,
    getListMiniGame,
    createMiniGame,
    getListGiftMiniGame,
    getDetailMiniGame,
    createGiftMiniGame,
    deleteMiniGame,
    deleteGiftMiniGame,
    getListResultMiniGame,
    updateSendNotification,
    updateMiniGame,
    updateGiftMiniGame,
    getDetailsGiftMiniGame,
    updateResultMiniGame,
    getListTurnUser,
    updateAddTurnUser,
    signIn,
    signOut,
    changePassWord,
    getBanner,
    deleteBanner,
    inactiveBanner,
    getAdvertising,
    deleteAdvertising,
    inactiveAdvertising,
    getPartner,
    getPartnerDetail,
    deletePartner,
    deactivatePartner,
    createPartner,
    updatePartner,
    getListUser,
    createUser,
    updateUser,
    inactiveUser,
    getListDiscountAdmin,
    getListUserRole,
    getListPartner,
    createDiscount,
    updateDiscount,
    updateDiscountStatus,
    getListOrder,
    updateOrderStatus,
    orderDetail,
    endOrders,
    generateDiscountCode,
    activeUser,
    activeCodeUser,
    getAllUsers,
    getAllOrders,
    getUserDetail,
    getBoxList,
    getAllCity,
    getListDistrict,
    getListWard,
    getBoxDetail,
    toggleBoxStatus,
    updateBox,
    addBox,
    getBoxTemplate,
    createPassWord,
    getBoxSlotList,
    getAllBoxes,
    deleteBoxes,
    toggleLockBox,
    openDoor,
    resetStatusBox,
    getBoxSlotDetail,
    updateBoxSlot,
    addBoxSlot,
    getListUserExcel,
    getListBoxExcel,
    getListOrderExcel,
    getListBannerExcel,
    updatePriceBox,
    getListHistoryPrice,
    searchListHistoryPrice,
    getAllBannerAdvertising,
    getBannerDetail,
    getOtherList,
    getPartnerAdvertissing,
    addBannerAdvertising,
    updateBannerAdvertising,
    getBannerBoxList,
    getArticles,
    searchArticles,
    getArticleDetail,
    updateArticle,
    deleteArticle,
    inactiveArticle,
    inactivateBanner,
    addArticle,
    getListArticleExcel,
    getListDriver,
    getDriverDetail,
    approveDriverkyc,
    getListDriverExcel,
    rebootBox,
    orderRevoked,
    getListOrderRevoked,
    getListRevokedExcel,
    changePasswordBox,
    createDiscountPromotion,
    discountDeatail,
    deleteVoucher,
    updateDiscountPromotion,
    inactiveVoucher,
    getListPromotion,
    promotionHidden,
    getPromotionDetail,
    updatePromotion,
    getListDiscount,
    getListVoucher,
    getListBoxDiscount,
    updateListBoxDiscount,
    getListBoxDiscountDetail,
    getListUserDiscountDetail,
    getListUsersDiscount,
    updateListUsersDiscount,
    deleteBoxesDiscount,
    deleteUsersDiscount,
    deletePromotion,
    updateDiscountAdmin,
    updateTargetUserDiscount,
    getListVoucherSelect,
    getListPartnerSelect,
    checkTargetUser,
    confirmChangeTargetUser,
    getListOrganization,
    createOrganization,
    updateOrganization,
    deleteDepartment,
    getListStaff,
    getStaffDetail,
    createEmployee,
    createPassWordEmployee,
    createGroupEmployee,
    getListUserEmployee,
    updateListUsersPermission,
    getListUserPermissionDetail,
    deleteEmployeePermission,
    getListPermission,
    getPermissionDetail,
    inactivePermission,
    updateGroupEmployee,
    getListUserPermission,
    createGroupPermission,
    detailGroupPermission,
    updateStaff,
    activeStaff,
    getListOrganizationParent,
    getDetailOrganization,
    resentSendNotication,
    getListDriverAccount,
    getDriverAccountDetail,
    inactiveDriverAccount,
    updateDriverAccountStatus,
    updateDriverAccount,
    createPassWordDriver,
  };
}

export default CreateApi;

import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const AdvertisingDetail = lazy(() => import('./advertising-detail'));
const AdvertisingManagementPage = lazy(() => import('./advertising-list'));
const AddAdvertising = lazy(() => import('./add-advertising'));

const route = [
  {
    identifier: FUNCTION_WEB.ADVERTISING_MANAGEMENT,
    path: '/advertising-management',
    element: AdvertisingManagementPage
  },
  {
    identifier: FUNCTION_WEB.ADVERTISING_DETAIL,
    path: '/advertising-management/:id',
    element: AdvertisingDetail
  },
  {
    identifier: FUNCTION_WEB.ADD_ADVERTISING_MANAGEMENT,
    path: '/advertising-management/add-advertising',
    element: AddAdvertising
  }
];

export default route;

import { ActionBody } from 'reduxes/types';
import { createActions, createReducer, DefaultActionTypes } from 'reduxsauce';
import SeamlessImmutable from 'seamless-immutable';
import { OrderManagementActionCreators, SetCurrentOrder, State, Action } from './types';

export type OrderManagementState = State;

const INITIAL_STATE = SeamlessImmutable<State | any>({
  currentOrder: {
    _id: '',
    code: '',
    box: {
      _id: '',
      name: '',
      address: ''
    },
    user: {
      _id: '',
      fullName: '',
      phoneNumber: '',
      email: '',
      userRoleId: [
        {
          _id: '',
          code: ''
        }
      ]
    },
    importTime: '',
    exportTime: '',
    orderStatus: null,
    packageType: '',
    amount: 0,
    note: '',
    boxSlot: { type: '' },
    blocks: 0,
    startTime: '',
    endTime: '',
    remainingTime: '',
    blockLength: 0
  }
});

export interface OrderManagementActionTypes extends DefaultActionTypes {
  SET_CURRENT_ORDER: 'SET_CURRENT_ORDER';
}

const { Types, Creators } = createActions<OrderManagementActionTypes, OrderManagementActionCreators>({
  setCurrentOrder: ['currentOrder']
});

export const OrderManagementType = Types;

export default Creators;

const setCurrentOrder: ActionBody<SetCurrentOrder> = (state, { currentOrder }) => {
  return {
    ...state,
    currentOrder: {
      ...state.currentOrder,
      box: {
        ...state.currentOrder.box
      },
      user: {
        ...state.currentOrder.user
      },
      boxSlot: {
        ...state.currentOrder.boxSlot
      },
      ...currentOrder
    }
  };
};

export const reducer = createReducer<State, Action>(INITIAL_STATE, {
  [Types.SET_CURRENT_ORDER]: setCurrentOrder
});

import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const BoxSlotManagement = lazy(() => import('./box-slot-list'));
const AddBoxSlot = lazy(() => import('./add-box-slot'));
const BoxSlotDetail = lazy(() => import('./box-slot-detail'));

const routes = [
  {
    identifier: FUNCTION_WEB.BOX_SLOT_MANAGEMENT,
    path: '/box-slot-management',
    element: BoxSlotManagement
  },
  {
    identifier: FUNCTION_WEB.BOX_SLOT_MANAGEMENT,
    path: '/box-slot-management/:box_id',
    element: BoxSlotManagement
  },
  {
    identifier: FUNCTION_WEB.BOX_SLOT_ADD,
    path: '/box-slot-management/add-box-slot/:box_id',
    element: AddBoxSlot
  },
  {
    identifier: FUNCTION_WEB.BOX_SLOT_DETAIL,
    path: '/box-slot-management/detail/:box_slot_id',
    element: BoxSlotDetail
  }
];

export default routes;

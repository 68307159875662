import { ActionBody } from 'reduxes/types';
import { createActions, createReducer, DefaultActionTypes } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { State, UserActionCreators, Action, SetAccessToken, SetUser, SetLanguage, SetUserPermission } from './types';

export type UserState = State;

const INITIAL_STATE = Immutable<State>({
  language: 'vi',
  isAuth: false,
  accessToken: '',
  refreshToken: '',
  user: {
    userRole: '',
    fullName: '',
    avatar: '',
    phoneNumber: '',
    email: '',
    username: ''
  },
  userPermission: []
});

export interface UserActionTypes extends DefaultActionTypes {
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN';
  SET_USER: 'SET_USER';
  LOGIN_SUCCESS: 'LOGIN_SUCCESS';
  LOGOUT: 'LOGOUT';
  SET_LANGUAGE: 'SET_LANGUAGE';
  SET_USER_PERMISSION: 'SET_USER_PERMISSION';
}

const { Types, Creators } = createActions<UserActionTypes, UserActionCreators>({
  setAccessToken: ['accessToken'],
  setUser: ['user'],
  loginSuccess: [''],
  logout: [''],
  setLanguage: ['language'],
  setUserPermission: ['userPermission']
});

export const UserTypes = Types;

export default Creators;

const logout: ActionBody = (state) => ({
  ...state,
  ...INITIAL_STATE,
  language: state.language
});

const loginSuccess: ActionBody = (state) => ({
  ...state,
  isAuth: true
});

const setAccessToken: ActionBody<SetAccessToken> = (state, { accessToken }) => {
  return {
    ...state,
    accessToken
  };
};

const setUser: ActionBody<SetUser> = (state, { user }) => {
  return {
    ...state,
    user
  };
};

const setLanguage: ActionBody<SetLanguage> = (state, { language }) => ({
  ...state,
  language
});

const setUserPermission: ActionBody<SetUserPermission> = (state, { userPermission }) => ({
  ...state,
  userPermission
});

export const reducer = createReducer<State, Action>(INITIAL_STATE, {
  [Types.SET_ACCESS_TOKEN]: setAccessToken,
  [Types.SET_USER]: setUser,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGOUT]: logout,
  [Types.SET_LANGUAGE]: setLanguage,
  [Types.SET_USER_PERMISSION]: setUserPermission,
});

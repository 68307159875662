import { ReactElement } from 'react';
import { Row, Col } from 'antd';

interface Props {
  title: string;
  rightContent?: ReactElement | ReactElement[];
  leftContent?: ReactElement | ReactElement[];
}

export default function NavPage({ title, rightContent, leftContent }: Props) {
  return (
    <Row justify="space-between" className="box-selector">
      <Col
        span={12}
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <h1 className="header-page" style={{ width: 500 }}>
          {title}
        </h1>
        {leftContent && leftContent}
      </Col>
      <Col
        span={8}
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {rightContent && rightContent}
      </Col>
    </Row>
  );
}

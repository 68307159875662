export default function CoinIcon({ imageStyles }: any) {
  return (
    <span className="anticon anticon-appstore ant-menu-item-icon">
      <svg
        width={22}
        height={22}
        viewBox="0 0 22 22  "
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        style={imageStyles}
        className="ant-menu-item-icon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0C4.477 0 0 4.477 0 10ZM6.5 12.1992H6.3999V14.1992H8.8999V16.1992H10.8999V16V14.1992H11H11.8999C12.5629 14.1992 13.1988 13.9358 13.6677 13.467C13.8851 13.2496 14.0583 12.9963 14.1816 12.721C14.3241 12.4026 14.3999 12.0548 14.3999 11.6992C14.3999 11.0362 14.1365 10.4003 13.6677 9.93145C13.1988 9.46261 12.5629 9.19922 11.8999 9.19922H7.8999C7.76729 9.19922 7.64012 9.14654 7.54635 9.05277C7.45258 8.959 7.3999 8.83183 7.3999 8.69922C7.3999 8.58251 7.44071 8.47 7.51442 8.38078C7.52446 8.36862 7.53511 8.3569 7.54635 8.34566C7.64012 8.2519 7.76729 8.19922 7.8999 8.19922H13.3999V8V6.19922H10.8999V4.19922H9H8.8999V6V6.19922H7.8999C7.23686 6.19922 6.60098 6.46261 6.13214 6.93145C5.96828 7.09531 5.82952 7.27957 5.71832 7.47826C5.51136 7.84809 5.3999 8.26791 5.3999 8.69922C5.3999 9.36226 5.66329 9.99814 6.13214 10.467C6.60098 10.9358 7.23686 11.1992 7.8999 11.1992H9.8999H11.8999C12.0325 11.1992 12.1597 11.2519 12.2535 11.3457C12.3472 11.4394 12.3999 11.5666 12.3999 11.6992C12.3999 11.7397 12.395 11.7797 12.3855 11.8184C12.3639 11.9064 12.3186 11.9877 12.2535 12.0528C12.1597 12.1465 12.0325 12.1992 11.8999 12.1992H6.5Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}

import { useState } from 'react';
import { Menu, Dropdown, Space } from 'antd';
import ChangePasswordForm from '../ChangePasswordForm';
import { ChangePasswordIcon, EditPassWordIcon, LogAvatarIcon } from 'components/icons';
import useAuthen from 'hooks/useAuthen';

export enum MenuKey {
  SIGN_OUT = 'SignOut',
  CHANGE_PASSWORD = 'ChangePassword'
}

const styleMenuItem = {
  paddingTop: 8,
  paddingBlock: 8,
  paddingRight: 12,
  paddingLeft: 12
};

function RightContent() {
  const [visible, setVisible] = useState(false);
  const { onSignOut } = useAuthen();

  function onMenuChange({ key }: any) {
    switch (key) {
      case MenuKey.SIGN_OUT: {
        onSignOut();
        break;
      }
      case MenuKey.CHANGE_PASSWORD: {
        setVisible(true);
      }
    }
  }

  function onChangeFormClose() {
    setVisible(false);
  }

  const menu = (
    <Menu
      onClick={onMenuChange}
      items={[
        {
          key: MenuKey.CHANGE_PASSWORD,
          label: (
            <Space style={styleMenuItem}>
              <EditPassWordIcon /> Đổi mật khẩu
            </Space>
          )
        },
        {
          key: MenuKey.SIGN_OUT,
          label: (
            <Space style={styleMenuItem}>
              <LogAvatarIcon />
              Đăng xuất
            </Space>
          )
        }
      ]}
    />
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <div className="px-5">
          <ChangePasswordIcon />
        </div>
      </Dropdown>
      <ChangePasswordForm visibleForm={visible} onClose={onChangeFormClose} />
    </>
  );
}

export default RightContent;

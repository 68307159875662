import { Images } from 'assets/Images';
export default function PassErrorIcon() {
  return (
    <img
      style={{
        width: 56,
        height: 56
      }}
      src={Images.icPassError}
      alt="PassErrorIcon"
    />
  );
}

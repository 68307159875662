import { Tooltip } from 'antd';
import { Images } from 'assets/Images';
import { IconSize, IconProps, IconStyles } from 'types/icon';

export default function DetailIcon({ size = IconSize.DEFAULT, className, onClick }: IconProps) {
  return (
    <Tooltip title="Chi tiết">
      <img
        src={Images.icDetail}
        alt="Detail"
        style={{
          width: size,
          height: size,
          ...IconStyles.default
        }}
        onClick={onClick}
        className={className}
      />
    </Tooltip>
  );
}

import React from 'react';
export default function OrderIcon({ imageStyles }: any) {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg width={24} height={24} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style={imageStyles} className="ant-menu-item-icon">
        <g clipPath="url(#clip0_10_38951)">
          <path
            d="M15.9731 8.27095C16.0167 8.25107 16.0537 8.21905 16.0797 8.17871C16.1056 8.13837 16.1194 8.09142 16.1194 8.04345C16.1194 7.99549 16.1056 7.94854 16.0797 7.9082C16.0537 7.86786 16.0167 7.83584 15.9731 7.81595L6.21608 3.36795C6.15217 3.33956 6.08302 3.32489 6.01308 3.32489C5.94315 3.32489 5.874 3.33956 5.81008 3.36795L1.99308 5.02395C1.94799 5.04317 1.90953 5.07521 1.8825 5.1161C1.85548 5.157 1.84106 5.20493 1.84106 5.25395C1.84106 5.30297 1.85548 5.35091 1.8825 5.3918C1.90953 5.4327 1.94799 5.46474 1.99308 5.48395L12.2831 9.77195C12.3466 9.79854 12.4149 9.81173 12.4837 9.8107C12.5525 9.80967 12.6204 9.79443 12.6831 9.76595L15.9731 8.27095Z"
            fill="currentColor"
          />
          <path
            d="M22.062 5.49997C22.1059 5.48022 22.1432 5.44818 22.1694 5.40774C22.1956 5.36729 22.2095 5.32014 22.2095 5.27197C22.2095 5.2238 22.1956 5.17666 22.1694 5.13621C22.1432 5.09576 22.1059 5.06373 22.062 5.04397L12.4 0.846974C12.2739 0.79191 12.1377 0.763489 12 0.763489C11.8624 0.763489 11.7262 0.79191 11.6 0.846974L8.38002 2.24697C8.3358 2.2666 8.29823 2.29864 8.27185 2.3392C8.24548 2.37976 8.23145 2.4271 8.23145 2.47547C8.23145 2.52385 8.24548 2.57119 8.27185 2.61175C8.29823 2.65231 8.3358 2.68435 8.38002 2.70397L18.08 7.12597C18.145 7.15554 18.2156 7.17084 18.287 7.17084C18.3584 7.17084 18.429 7.15554 18.494 7.12597L22.062 5.49997Z"
            fill="currentColor"
          />
          <path
            d="M0.846 6.62995C0.807998 6.61414 0.766683 6.60796 0.72572 6.61196C0.684758 6.61596 0.645416 6.63001 0.611186 6.65286C0.576957 6.67571 0.548899 6.70666 0.529503 6.74296C0.510107 6.77926 0.499973 6.81979 0.5 6.86095V18.0069C0.499984 18.2043 0.558372 18.3973 0.667811 18.5615C0.77725 18.7258 0.932846 18.854 1.115 18.9299L11.4 23.2169C11.438 23.2327 11.4793 23.2389 11.5203 23.2349C11.5612 23.2309 11.6006 23.2169 11.6348 23.194C11.669 23.1712 11.6971 23.1402 11.7165 23.1039C11.7359 23.0676 11.746 23.0271 11.746 22.9859V11.3399C11.7459 11.2906 11.7312 11.2423 11.7038 11.2012C11.6765 11.1601 11.6375 11.128 11.592 11.1089L0.846 6.62995Z"
            fill="currentColor"
          />
          <path
            d="M13.25 22.9439C13.2502 22.9857 13.2608 23.0267 13.2809 23.0632C13.3009 23.0998 13.3298 23.1308 13.3648 23.1535C13.3999 23.1761 13.44 23.1896 13.4816 23.1929C13.5232 23.1961 13.5649 23.1889 13.603 23.1719L22.914 18.9399C23.0887 18.8605 23.2368 18.7324 23.3408 18.5711C23.4447 18.4097 23.4999 18.2219 23.5 18.0299V6.88595C23.5 6.84414 23.4896 6.80299 23.4696 6.76627C23.4496 6.72955 23.4208 6.69842 23.3856 6.67574C23.3505 6.65306 23.3103 6.63954 23.2686 6.63643C23.2269 6.63333 23.1851 6.64072 23.147 6.65795L13.4 11.0899C13.3562 11.1095 13.3189 11.1414 13.2928 11.1816C13.2667 11.2219 13.2529 11.2689 13.253 11.3169L13.25 22.9439Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_10_38951">
            <rect width={24} height={24} fill="currentColor" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
}

export default function NotificationIcon({ imageStyles }: any) {
  return (
    <span className="anticon anticon-appstore ant-menu-item-icon">
      <svg
        width={22}
        height={22}
        viewBox="0 0 22 22  "
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        style={imageStyles}
        className="ant-menu-item-icon"
      >
        <path
          d="M12.75 19C12.75 19.1989 12.671 19.3897 12.5303 19.5303C12.3897 19.671 12.1989 19.75 12 19.75H5.99997C5.80106 19.75 5.61029 19.671 5.46964 19.5303C5.32899 19.3897 5.24997 19.1989 5.24997 19C5.24997 18.8011 5.32899 18.6103 5.46964 18.4697C5.61029 18.329 5.80106 18.25 5.99997 18.25H12C12.1989 18.25 12.3897 18.329 12.5303 18.4697C12.671 18.6103 12.75 18.8011 12.75 19ZM17.705 15.9997C17.5751 16.2288 17.3863 16.4191 17.1583 16.551C16.9303 16.6829 16.6712 16.7516 16.4078 16.75H1.59213C1.32841 16.7496 1.06944 16.6798 0.841346 16.5474C0.613247 16.415 0.424079 16.2249 0.292914 15.9961C0.161749 15.7673 0.0932245 15.508 0.0942496 15.2443C0.0952748 14.9805 0.165813 14.7218 0.298753 14.494C0.855347 13.5363 1.519 11.719 1.519 8.5V7.75C1.51649 5.76344 2.30322 3.85723 3.70613 2.45072C5.10904 1.0442 7.01322 0.252584 8.99978 0.25L9.05697 0.250188C13.151 0.280563 16.4817 3.68294 16.4817 7.83475V8.5C16.4817 11.7192 17.145 13.5366 17.7014 14.4944C17.8358 14.7223 17.907 14.9819 17.9076 15.2466C17.9083 15.5112 17.8383 15.7711 17.705 15.9997Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}

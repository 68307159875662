import { Images } from 'assets/Images';

interface Props {
  className?: string;
}

export default function UnlockNotBagIcon({ className }: Props) {
  return (
    <img
      style={{
        width: 20,
        height: 20
      }}
      className={className}
      src={Images.icUnlockNotBag}
      alt="UnLockBagIcon"
    />
  );
}

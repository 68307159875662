import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const ManagerBannerPage = lazy(() => import('./index'));
const AddBannerPage = lazy(() => import('./add-banner'));
const ManagerPartnerPage = lazy(() => import('./manager-partner'));
const ManagerAdvertisingPage = lazy(() => import('./manager-advertising'));

const route = [
  {
    identifier: FUNCTION_WEB.BANNER_MANAGEMENT,
    path: '/manager-banner',
    element: ManagerBannerPage
  },
  {
    identifier: FUNCTION_WEB.ADD_BANNER,
    path: '/add-banner',
    element: AddBannerPage
  },
  {
    identifier: FUNCTION_WEB.PARTNER_MANAGEMENT,
    path: '/manager-partner',
    element: ManagerPartnerPage
  },
  {
    identifier: FUNCTION_WEB.ADVERTISING_MANAGEMENT,
    path: '/manager-advertising',
    element: ManagerAdvertisingPage
  }
];

export default route;

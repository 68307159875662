import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const PromotionDetail = lazy(() => import('./promotion-detail'));
const PromotionManagementPage = lazy(() => import('./promotion-list'));
const AddPromotion = lazy(() => import('./promotion-add'));

const route = [
  {
    identifier: FUNCTION_WEB.PROMOTION_MANAGEMENT,
    path: '/promotion-management',
    element: PromotionManagementPage
  },
  {
    identifier: FUNCTION_WEB.PROMOTION_MANAGEMENT_DETAIL,
    path: '/promotion-management/:_id',
    element: PromotionDetail
  },
  {
    identifier: FUNCTION_WEB.ADD_PROMOTION_MANAGEMENT_DETAIL,
    path: '/promotion-management/promotion-add',
    element: AddPromotion
  }
];

export default route;

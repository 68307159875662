import ApiSauce, { ApiResponse } from 'apisauce';

enum ResponseStatusCodes {
  CLIENT_ERROR = -970,
  TIMEOUT_ERROR = -980,
  NETWORK_ERROR = -999,
  BUSINESS_ERROR = -1
}

export function responseTransform(response: ApiResponse<any>) {
  if (response.ok && response.data?.errorCode === 0) {
    response.data = response.data?.data;
    response.ok = true;
  } else {
    response.ok = false;

    // handle error
    switch (response.problem) {
      case ApiSauce.CONNECTION_ERROR:
      case ApiSauce.TIMEOUT_ERROR:
        response.data = {
          errorCode: ResponseStatusCodes.TIMEOUT_ERROR,
          message: 'errors.http.timeout',
          errors: [],
          data: {}
        };
        break;

      case ApiSauce.NETWORK_ERROR:
        response.data = {
          errorCode: ResponseStatusCodes.NETWORK_ERROR,
          message: 'errors.http.network',
          errors: [],
          data: {}
        };
        break;
      case ApiSauce.CLIENT_ERROR:
        response.data = {
          errorCode: ResponseStatusCodes.CLIENT_ERROR,
          message: 'errors.http.client',
          errors: [],
          data: {}
        };
        break;
      default:
        if (response.data?.errors?.length === 0) {
          response.data = {
            errorCode: ResponseStatusCodes.BUSINESS_ERROR,
            message: response.data?.message,
            errors: [],
            data: {}
          };
        } else {
          response.data = {
            errorCode: ResponseStatusCodes.BUSINESS_ERROR,
            message: response.data?.errors[0]?.msg,
            errors: [],
            data: {}
          };
        }
    }
  }
}

export function getFileNameByPath(path: any) {
  const lastDotIndex = path.lastIndexOf('.');
  const extension = path.slice(lastDotIndex);
  return `${new Date().toISOString()}${extension}`;
}

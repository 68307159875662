import { useState, useEffect } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { PassErrorIcon, CloseIcon } from 'components/icons';
import useAuthen from 'hooks/useAuthen';
import { api } from 'service';

type Props = {
  visibleForm: boolean;
  onClose: () => void;
};

function ChangePasswordForm({ visibleForm, onClose }: Props) {
  const [visibleError, setVisibleError] = useState(false);
  const { onSignOut } = useAuthen();
  const [loading, setLoading] = useState<boolean>(false);

  function PassError() {
    return (
      <Modal
        style={{ width: 350, height: 212 }}
        className="edit-pass__modal-pass-error"
        onCancel={() => {
          setVisibleError(false);
        }}
        visible={visibleError}
        footer={null}
      >
        <div className="pass-error">
          <PassErrorIcon />
          <p className="text-pass-error mb-4 mt-4">Đổi mật khẩu thất bại</p>
          <div className="pass-text-error mb-2">
            Có lỗi xảy ra trong quá trình đổi mật khẩu<br></br>Hãy thực hiện lại thao tác này.
          </div>
        </div>
      </Modal>
    );
  }

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  useEffect(() => {
    forceUpdate({});
  }, []);

  const onFinish = async (values: any) => {
    setLoading(true);
    const response = await api.changePassWord({
      password: values.passpresent,
      newPassword: values.pass_new,
      confirmNewPassword: values.confirm_pass
    });
    setLoading(false);

    if (response.ok) {
      return onSignOut();
    } else {
      setVisibleError(true);
      clearData();
      return;
    }
  };

  async function clearData() {
    onClose();
    form.resetFields();
  }

  return (
    <Modal
      destroyOnClose
      maskClosable
      style={{ width: 398, height: 402 }}
      forceRender={true}
      className="edit-pass-staff"
      onCancel={clearData}
      visible={visibleForm}
      footer={null}
      closeIcon={<CloseIcon />}
    >
      <div className="edit-pass-user">Đổi mật khẩu</div>
      <Form form={form} onFinish={onFinish} layout="vertical" initialValues={{ remember: true }} autoComplete="off">
        <Form.Item
          name="passpresent"
          label={
            <p className="p_current_pass mt-1">
              Mật khẩu hiện tại
              <span className="mt-2" style={{ fontSize: 20, color: 'red' }}>
                {' '}
                *
              </span>
            </p>
          }
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mật khẩu cũ '
            }
          ]}
        >
          <Input.Password type="text" className="input_pass" placeholder="Nhập mật khẩu cũ" allowClear />
        </Form.Item>
        <Form.Item
          name="pass_new"
          label={
            <p className="p_current_pass mt-1">
              Mật khẩu mới<span style={{ fontSize: 20, color: 'red' }}> *</span>
            </p>
          }
          rules={[
            {
              required: true,
              message: 'Mật khẩu nhập lại không chính xác'
            },
            {
              min: 6,
              message: 'Mật khẩu mới tối thiểu 6 kí tự'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('passpresent') !== value) {
                  return Promise.resolve('Mật khẩu mới không được trùng mật khẩu cũ');
                }

                return Promise.reject(new Error('Mật khẩu mới không được trùng mật khẩu cũ'));
              }
            })
          ]}
        >
          <Input.Password className="input_pass" placeholder="Nhập mật khẩu mới" allowClear />
        </Form.Item>
        <Form.Item
          name="confirm_pass"
          label={
            <p className="p_current_pass mt-1">
              Nhập lại mật khẩu mới<span style={{ fontSize: 20, color: 'red' }}> *</span>
            </p>
          }
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Mật khẩu nhập lại không chính xác'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('pass_new') === value) {
                  return Promise.resolve('Mật khẩu nhập lại chính xác');
                }

                return Promise.reject(new Error('Mật khẩu nhập lại không chính xác'));
              }
            })
          ]}
        >
          <Input.Password className="input_pass" placeholder="Nhập lại mật khẩu mới" allowClear />
        </Form.Item>
        {PassError()}
        <Form.Item shouldUpdate>
          <Button size="large" className="button_change_pass mt-2" type="primary" htmlType="submit" loading={loading}>
            Đổi mật khẩu
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ChangePasswordForm;

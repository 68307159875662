// import { DISCOUNT_TYPE } from 'pages/discount-management/types';

import { PROMOTION_PROGRAM_TYPE } from "pages/discount-management/types";

export type AddDiscountProps = {
  isOpen: boolean;
  onCancel: Function | any;
  generateDiscount: string;
};

export enum DISCOUNT_VIEW_MODE {
  ADD = 'ADD',
  UPDATE = 'UPDATE'
}

export type FormAddFields = {
  name: string;
  partner: string;
  type: PROMOTION_PROGRAM_TYPE;
  description: string;
  code?: string;
  amount?: number;
  maxAmount?: number;
  minimumPrice?: number;
  quantity?: number;
  usageCount?: number;
  startTime: string;
  endTime: string;
  status?: string[];
  targetUser?: string[];
  note?: string;
};

export type CreateDiscountParams = FormAddFields & {
  isLimited?: boolean;
};

export type UpdateDiscountParams = {
  _id: string;
  type: PROMOTION_PROGRAM_TYPE;
  amount?: number;
  maxAmount?: number;
  isLimited: boolean;
  quantity?: number;
  usageCount?: number;
  minimumPrice?: number;
  note?: string;
  startTime: string;
  endTime: string;
  partner?: string;
};

export type UpdateDiscountStatusParams = {
  _id: string;
  status: number;
};

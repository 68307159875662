export const DEFAULT_API = process.env.REACT_APP_API;

/**
 * The key we'll be saving our state as within async storage.
 */
export const ROOT_STATE_STORAGE_KEY = 'root';

/**
 * Oauth
 */
export const CLIENT_ID = '94e3bcfd-5eb7-4b95-9cf1-cec9873553dd';
export const CLIENT_SECRET = 'TwCN5lpGrpRAG0oLJMOKsF3VbgUVIO829HeBBGa6';
export const PLATFORM = 'ADMIN';

import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { BackDetaiUser } from 'components/icons';
import { ReactElement } from 'react';
import DotIcon from 'assets/svg/components/DotIcon';

interface Props {
  to: string;
  label: string;
  badge?: ReactElement | ReactElement[];
  footer?: ReactElement | ReactElement[];
  icon?: ReactElement | ReactElement[];
}

export default function GoBack({ to, label, badge, footer, icon }: Props) {
  return (
    <div className="mb-4 mt-1">
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Link to={to}>
          <BackDetaiUser />
        </Link>
        <h1 className="header-page mb-0">{label}</h1>
        {icon && icon}
        {badge && badge}
      </div>
      {footer && (
        <Typography.Text type="secondary" className="ml-6">
          {footer}
        </Typography.Text>
      )}
    </div>
  );
}

import { useState } from 'react';
import { Layout, Menu } from 'antd';
import { MenuType } from 'api/service-type';
import { ScrollBar } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { RootState } from 'reduxes/reducers';
import { arrayToTree, queryAncestors } from 'utils';
import iconMap from 'utils/icon-map';
import { Images } from 'assets/Images';
import './style.scss';

interface Props {
  menus: Array<MenuType>;
  collapsed: boolean;
  isMobile: boolean;
  onCollapseChange: (collapsed: boolean) => void;
}

function Sider({ menus, collapsed, isMobile, onCollapseChange }: Props) {
  const location = useLocation();
  const { t } = useTranslation();
  const [openKeysState, setOpenKeysState] = useState<any[]>([]);
  const collapsedApp = useSelector((state: RootState) => state.app.collapsed);
  const menuTree = arrayToTree(menus, 'id', 'menu_parent_id');

  const currentMenu = menus.find((_) => {
    if (_.route) {
      return location.pathname.includes(_.route);
    }

    return false;
  });

  const selectedKeys = currentMenu ? queryAncestors(menus, currentMenu, 'menu_parent_id').map((_) => _.id.toString()) : [];

  const menuProps = collapsed ? {} : { openKeys: openKeysState };

  const onOpenChange = (params: Array<string>) => {
    const rootSubmenuKeys = menus.filter((_) => !_.menu_parent_id).map((_) => _.id.toString());

    const latestOpenKey = params.find((key) => openKeysState.indexOf(key) === -1);

    let newOpenKeys = params;
    if (!latestOpenKey) {
      newOpenKeys = [];
    } else if (rootSubmenuKeys.indexOf(latestOpenKey) !== -1) {
      newOpenKeys = [latestOpenKey];
    }
    setOpenKeysState(newOpenKeys);
  };

  const generateMenus = (data: Array<any>) => {
    return data.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu
            key={item.id}
            icon={item.icon && iconMap[item.icon]}
            title={
              <span className="ant-menu-title-content" style={{ paddingLeft: '6px' }}>
                {t(item.name)}
              </span>
            }
          >
            {generateMenus(item?.children)}
          </Menu.SubMenu>
        );
      }

      return (
        <Menu.Item icon={item?.icon ? iconMap[item.icon] : undefined} key={item.id}>
          <NavLink to={item?.route || '#'}>{t(item.name)}</NavLink>
        </Menu.Item>
      );
    });
  };

  return (
    <Layout.Sider
      width="256"
      breakpoint="lg"
      trigger={null}
      collapsible
      collapsed={collapsed}
      onBreakpoint={!isMobile ? onCollapseChange : undefined}
      className="sider"
    >
      <div className="brand">
        <div className="logo">
          {collapsed ? (
            <img alt="logo" className="icon" src={Images.logoIcon} />
          ) : (
            <div style={{ transition: 'all 1s ease-out' }}>
              <img alt="logo" className="icon" src={Images.logoIcon} />
              <img alt="logo" className="text" src={Images.logoText} />
            </div>
          )}
        </div>
      </div>

      <div className="menu-container">
        <ScrollBar
          options={{
            suppressScrollX: true
          }}
        >
          <Menu
            theme="light"
            mode="inline"
            onOpenChange={onOpenChange}
            selectedKeys={selectedKeys}
            onClick={
              isMobile
                ? () => {
                    onCollapseChange(true);
                  }
                : undefined
            }
            {...menuProps}
          >
            {generateMenus(menuTree)}
          </Menu>
        </ScrollBar>
      </div>
      <div className="footer-sider">
        <div className="button" onClick={() => onCollapseChange(!collapsedApp)}>
          <img alt="icon" className="icon-toggle-drawer" src={collapsed ? Images.iconToggleOpen : Images.iconToggle}></img>
        </div>
      </div>
    </Layout.Sider>
  );
}

export default Sider;

import { useSelector } from "react-redux";
import { RootState } from "reduxes/reducers";
import { PERMISSION_TYPE } from "types/permission";

function PermissionCheck(props: any) {
    let couldShow = false;
    if(props.permission === PERMISSION_TYPE.BYPASS) {
        couldShow = true;
    }
    else {
        const userPermission = useSelector((state: RootState) => state.user.userPermission);
        const getFunction = userPermission.filter((x: any) =>
            x.code == props.function && x.buttonId.includes(props.permission)
        )
        couldShow = getFunction && getFunction.length > 0 ? true : false ;
    }
  
    return couldShow ? props.children : null;
}
export default PermissionCheck;
import { IconProps, IconSize, IconStyles } from 'types/icon';
import Delete from 'assets/imgs/Delete.svg';
import { Tooltip } from 'antd';
export default function DeleteIcon({ size = IconSize.DEFAULT, className, onClick }: IconProps) {
  return (
    <Tooltip title="Xoá">
      <img
        src={Delete}
        alt="Edit"
        style={{
          width: size,
          height: size,
          ...IconStyles.default
        }}
        className={className}
        onClick={onClick}
      />
    </Tooltip>
  );
}

import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const StaffListPage = lazy(() => import('./staff-list'));
const AddStaff = lazy(() => import('./add-staff'));
const StaffDetail = lazy(() => import('./staff-detail'));

const route = [
  {
    identifier: FUNCTION_WEB.USER_MANAGEMENT,
    path: '/staff-management',
    element: StaffListPage
  },
  {
    identifier: FUNCTION_WEB.ADD_STAFF,
    path: '/staff-management/add-staff',
    element: AddStaff
  },
  {
    identifier: FUNCTION_WEB.USER_DETAIL,
    path: '/staff-management/:id',
    element: StaffDetail
  }
];

export default route;

import { Tooltip } from 'antd';
import LockRed from 'assets/imgs/LockRed.svg';
import { IconProps, IconSize, IconStyles } from 'types/icon';

export default function LockRedIcon({ size = IconSize.DEFAULT, onClick, className }: IconProps) {
  return (
    <Tooltip title="Khoá">
      <img
        src={LockRed}
        alt="Lock"
        style={{
          width: size,
          height: size,
          ...IconStyles.default
        }}
        onClick={onClick}
        className={className}
      />
    </Tooltip>
  );
}

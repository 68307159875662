import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const Notification = lazy(() => import('./notification-list'));
const NotificationAdd = lazy(() => import('./components/notification-add'));
const DetailNotification = lazy(() => import('./components/notification-detail'));

const route = [
  {
    identifier: FUNCTION_WEB.NOTIFICATION_MANAGEMENT,
    path: '/notification-management',
    element: Notification
  },
  {
    identifier: FUNCTION_WEB.NOTIFICATION_ADD,
    path: '/notification-management/add-notification',
    element: NotificationAdd
  },
  {
    identifier: FUNCTION_WEB.NOTIFICATION_UPDATE,
    path: '/notification-management/update-notification/:_id',
    element: DetailNotification
  }
];

export default route;

import { lazy } from 'react';
import { FUNCTION_WEB } from 'types/permission';

const PageMiniGame = lazy(() => import('./mini-game-list'));
const MiniGameAdd = lazy(() => import('./components/mini-game-add'));
const DetailMiniGame = lazy(() => import('./components/mini-game-detail'));
const ListMiniGameWin = lazy(() => import('./components/mini-game-list-win'));
const ListAddTurnForUsers = lazy(() => import('./components/mini-game-add-turn'));

const route = [
  {
    identifier: FUNCTION_WEB.MINI_GAME_MANAGEMENT,
    path: '/mini-game-management',
    element: PageMiniGame
  },
  {
    identifier: FUNCTION_WEB.MINI_GAME_ADD,
    path: '/mini-game-management/add-mini-game',
    element: MiniGameAdd
  },
  {
    identifier: FUNCTION_WEB.MINI_GAME_UPDATE,
    path: '/mini-game-management/detail-mini-game/:_id',
    element: DetailMiniGame
  },
  {
    identifier: FUNCTION_WEB.MINI_GAME_WIN,
    path: '/mini-game-management/list-win/:_id',
    element: ListMiniGameWin
  },
  {
    identifier: FUNCTION_WEB.MINI_GAME_ADD_TURN,
    path: '/mini-game-management/add-turn',
    element: ListAddTurnForUsers
  }
];

export default route;

import { Outlet } from 'react-router-dom';
import BaseLayout from 'layouts/base-layout';
import { observer } from 'mobx-react-lite';
import { refreshLanguage } from 'utils';
import { useSelector } from 'react-redux';
import { RootState } from 'reduxes/reducers';

function Layout() {
  const userState = useSelector((state: RootState) => state.user);

  refreshLanguage(userState.language);

  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  );
}

export default observer(Layout);

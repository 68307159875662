export enum BOX_SLOT_STATUS {
  AVAILABLE = 1,
  UNAVAILABLE = 2,
  SAVEFORORDER = 3,
  LOCK = 4,
  ISOPEN = 10
}

export enum BOX_LOCK {
  UNLOCK = 0,
  LOCK = 1
}

export interface GetBoxSlotListRequestDTO {
  page: number;
  pageSize: number;
  boxId: string;
  status: any;
  door?: any;
}

export enum BOX_SLOT_TYPE {
  S = 'S',
  M = 'M',
  L = 'L'
}

export type FormSearchProps = {
  boxId: string;
  handleSearch: (values: GetBoxSlotListRequestDTO) => Promise<any>;
};

export type UpdateBoxSlotRequestDTO = {
  type: BOX_SLOT_TYPE;
  door: number;
  board: number;
};

export type AddBoxSlotRequestDTO = {
  name: string;
  type: BOX_SLOT_TYPE;
  status: BOX_SLOT_STATUS;
  door?: number;
  board?: number;
  cell?: number;
  lock: BOX_LOCK;
};

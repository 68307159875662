export enum USER_ROLE {
  ADMIN = 'admin',
  CSKH = 'cskh',
  MKT = 'mkt',
  TTNB = 'ttnb',
  VH = 'vh',
  VH1 = 'vanhanh',
  TH = 'thuhoi'
}

export enum FUNCTION_WEB {
  DASHBOARD = 'dashboard',

  ADVERTISING_MANAGEMENT = 'advertising-management',
  ADVERTISING_DETAIL = 'advertising-detail',
  ADD_ADVERTISING_MANAGEMENT = 'add-advertising-management',

  USER_MANAGEMENT = 'user-management',
  USER_DETAIL = 'user-detail',

  DISCOUNT_MANAGEMENT = 'discount-management',
  ADD_DISCOUNT = 'add-discount',
  DISCOUNT_DETAIL = 'discount-detail',

  PRICE_MANAGEMENT = 'price-management',

  ADD_BANNER = 'add-banner',

  PARTNER_MANAGEMENT = 'manager-partner',

  PROMOTION_MANAGEMENT = 'promotion-management',
  PROMOTION_MANAGEMENT_DETAIL = 'promotion-management-detail',

  DEPARTMENT_MANAGEMENT = 'department-management',
  STAFF_MANAGEMENT = 'staff-management',
  ADD_STAFF = 'add-staff',
  PERMISSION_MANAGEMENT = 'permission-management',
  PERMISSION_MANAGEMENT_ADD = 'permission-add',
  PERMISSION_MANAGEMENT_DETAIL = 'permission-detail',
  DRIVER_MANAGEMENT = 'driver-management',
  MINI_GAME_MANAGEMENT = 'mini-game-management',
  MINI_GAME_ADD = 'mini-game-add',
  MINI_GAME_UPDATE = 'mini-game-update',
  MINI_GAME_WIN = 'mini-game-win',
  MINI_GAME_ADD_TURN = 'mini-game-add-turn',

  NOTIFICATION_MANAGEMENT = 'notification-management',
  NOTIFICATION_ADD = 'notification-add',
  NOTIFICATION_UPDATE = 'notification-update',

  DRIVER_ACCOUNT_MANAGEMENT = 'driver-account-management',


  // cskh
  ORDER_MANAGEMENT = 'order-management',
  ORDER_MANAGEMENT_DETAIL = 'order-management-detail',

  RETRIEVE_MANAGENENT = 'retrieve-management',
  RETRIEVE_MANAGENENT_DETAIL = 'retrieve-management-detail',

  BOX_MANAGEMENT = 'box-management',
  ADD_BOX_MANAGEMENT = 'add-box-management',
  BOX_MANAGEMENT_DETAIL = 'box-management-detail',

  BOX_SLOT_MANAGEMENT = 'box-slot-management',
  BOX_SLOT_DETAIL = 'box-slot-detail',
  BOX_SLOT_ADD = 'box-slot-add',

  // mkt
  BANNER_MANAGEMENT = 'manager-banner',

  ARTICLE_MANAGEMENT = 'article-management',
  ARTICLE_DETAIL = 'article-management-detail',
  ADD_ARTICLE_DETAIL = 'add-article-management',

  ADD_PROMOTION_MANAGEMENT_DETAIL = 'ADD_PROMOTION_MANAGEMENT_DETAIL'
}
export enum ROUTER_PATH {
  USER_MANAGEMENT = '/user-management',
  ORDER_MANAGEMENT = '/order-management',
  RETRIEVE_MANAGENENT = '/retrieve-management',
  DISCOUNT_MANAGEMENT = '/discount-management',
  ADD_DISCOUNT = '/add-discount',
  BOX_GROUP = '/box-group',
  BOX_MANAGEMENT = '/box-management',
  BOX_SLOT_MANAGEMENT = '/box-slot-management',
  ADVERTISING_MANAGEMENT = '/advertising-management',
  PRICE_MANAGEMENT = '/price-management',
  ARTICLE_MANAGEMENT = '/article-management',
  DRIVER_MANAGEMENT = '/driver-management',
  PROMOTION_MANAGEMENT = 'promotion-management',
  DEPARTMENT_MANAGEMENT = 'department-management',
  STAFF_MANAGEMENT = 'staff-management',
  ADD_STAFF = 'add-staff',
  PERMISSION_MANAGEMENT = '/permission-management',
  NOTIFICATION_MANAGEMENT = '/notification-management',
  MINI_GAME_MANAGEMENT = '/mini-game-management',
  DRIVER_GROUP = '/driver-group',
  DRIVER_ACCOUNT_MANAGEMENT = '/driver-account-group',
}

export const CSKH_ROLES = [
  ROUTER_PATH.ORDER_MANAGEMENT,
  ROUTER_PATH.BOX_GROUP,
  ROUTER_PATH.BOX_MANAGEMENT,
  ROUTER_PATH.BOX_SLOT_MANAGEMENT,
  ROUTER_PATH.DRIVER_MANAGEMENT
];

export const MKT_ROLES = [ROUTER_PATH.ADVERTISING_MANAGEMENT, ROUTER_PATH.ARTICLE_MANAGEMENT];

export const TTNB_ROLES = [ROUTER_PATH.DRIVER_MANAGEMENT];

export const VH_ROLES = [
  ROUTER_PATH.BOX_GROUP,
  ROUTER_PATH.BOX_MANAGEMENT,
  ROUTER_PATH.BOX_SLOT_MANAGEMENT,
  ROUTER_PATH.ORDER_MANAGEMENT,
  ROUTER_PATH.RETRIEVE_MANAGENENT
];
export const VH1_ROLES = [
  ROUTER_PATH.BOX_GROUP,
  ROUTER_PATH.BOX_MANAGEMENT,
];

export const TH_ROLES = [ROUTER_PATH.RETRIEVE_MANAGENENT];

// export enum PERMISSION_TYPE {
//   BYPASS = 'bypass',
//   UPDATE = 'UPDATE'
// }

export enum PERMISSION_TYPE {
  CREATE = 'CREATE', //Thêm mới
  UPDATE = 'UPDATE', //Cập nhật
  DELETE = 'DELETE', //Xóa
  DETAIL = 'DETAIL', //Chi tiết
  SEARCH = 'SEARCH', //Tìm kiếm
  EXPORT_EXCEL = 'EXPORT_EXCEL', //Xuất File Excel
  LOCK = 'LOCK', //Khóa
  UNLOCK = 'UNLOCK', //Mở khóa
  RESET = 'RESET', //Khởi động lại
  HIDE = 'HIDE', //Ẩn/Hiện
  DETAIL_BOX_SLOT = 'DETAIL_BOX_SLOT', //Chi tiết Ô tủ
  END_ORDER = 'END_ORDER', //Kết thúc
  REVOKED = 'REVOKED', //Thu hồi
  CHANGE_PASS_BOX = 'CHANGE_PASS_BOX', //Đổi mật khẩu tủ
  OPEN_BOX = 'OPEN_BOX', //Mở ô tủ
  DELETE_STATUS = 'DELETE_STATUS', //Xóa trạng thái
  EXPORT_EXCEL_TEMPLATE = "EXPORT_EXCEL_TEMPLATE", //Xuất File Excel Mẫu
  IMPORT_EXCEL_TEMPLATE = "IMPORT_EXCEL_TEMPLATE", //Nhập File Excel Mẫu
  BYPASS = 'bypass'
}
